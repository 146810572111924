.formWrapper {
    margin: 24px 42px;

    @media only screen and (max-width: 500px) {
        margin: 20px;
    }
}

.formSection {
    min-height: 300px;
    > h3 {
        font: 14px/20px var(--font-regular);
        color: #011a3c;
        margin: 0 0 16px 0;

        strong {
            font-family: var(--font-bold);
        }
    }

    > p {
        font: 14px/18px var(--font-light);
        color: #4d4d4d;
        margin: 0 0 14px 0;
    }

    .originalDeposit {
        display: flex;
        background-color: #e2eeff;
        padding: 8px 15px;
        justify-content: space-between;
        margin-bottom: 16px;
        border-radius: 4px;
        color: #3d5075;

        p {
            font: 12px/18px var(--font-regular);
        }
        span {
            display: flex;
            align-items: center;
            gap: 2px;

            svg {
                margin-top: -0.5px;
            }
        }

        strong {
            color: #004aad;
            font: 14px/120% var(--font-bold);
        }
    }
}

.paymentDetails {
    padding: 24px 42px;

    .header {
        h6 {
            color: #011a3c;
            font-size: 14px;
            line-height: 150%;
        }

        p {
            color: #4d4d4d;
            font: 12px/18px var(--font-light);
        }
    }

    .totalAmount {
        margin: 24px 0;
        border-radius: 12px;
        border: 1px solid #e2eeff;
        background: #f9fcff;
        display: flex;
        padding: 16px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        align-self: stretch;

        p {
            text-transform: uppercase;
            color: rgba(1, 26, 60, 0.85);
            font: 10px / normal var(--font-medium);
            letter-spacing: 0.55px;
        }

        h5 {
            color: #070c18;
            font: 18px/24px var(--font-bold);
        }
    }

    .depositInfo {
        border-radius: 12px;
        padding: 16px;
        border-radius: 12px;
        border: 1px solid #e2eeff;
        background: #f9fcff;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 32px;

        .item {
            p {
                text-transform: uppercase;
                color: rgba(1, 26, 60, 0.85);
                font: 10px / normal var(--font-medium);
                letter-spacing: 0.55px;
                margin-bottom: 8px;
            }

            h6 {
                color: #070c18;
                font: 14px/16.8px var(--font-bold);
            }

            &:nth-of-type(even) {
                justify-self: flex-end;
                text-align: right;
            }
        }
    }
}

.successIcon {
    width: 120px;
    height: 120px;
    margin-bottom: 22px;
}
