.PlanDetailsTabs {
    padding-top: 30px;
}

.PlanWrapper {
    display: flex;
    justify-content: center;
}

.TopRectangle {
    height: 151px;
    width: 412px;
    background: #f7f7f7;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    font-family: 'SF-Pro-Display';
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    @media screen and (max-width: 500px) {
        width: 100%;
    }
}

.Title {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.14em;
    text-transform: uppercase;
    color: #000000;
    margin-right: 5px;
}

.PaidStatus {
    width: 54px;
    height: 23px;
    background: #edfff5;
    border-radius: 3px;
    font-family: 'SF-Pro-Display-Bold';
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.055em;
    color: #32cc79;
    text-align: center;
    padding: 5px;
    margin-bottom: 0px;
}

.Subtitle {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-bottom: 0px;
    margin-top: 10px;
}

.BottomRectangle {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    font-family: 'SF-Pro-Display';
}

.BottomRectangleTitle {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.055em;
    color: rgba(1, 26, 60, 0.85);
    text-transform: uppercase;
    margin-bottom: 5px;
}

.BottomRectangleSubTitle {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 13px;
    line-height: 25px;
    color: #011a3c;
}

.LeftWrapper {
    width: 200px;
}

.BtnWrapper {
    display: flex;
    justify-content: center;
    gap: 18px;
    @media screen and (max-width: 500px) {
        margin-top: 10px;
    }
}

.OkayBtn {
    width: 134px;
    height: 50px;
    background: #004aad;
    border-radius: 30px;
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
    border: none;
    cursor: pointer;
}

.DescriptionWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 37px;
    font-family: 'SF-Pro-Display';
    overflow: scroll;
    max-height: 124px;
    height: 100%;

    @media screen and (max-width: 500px) {
        margin: 30px 20px 0px;
    }
}

.DescriptionText {
    width: 304px;
    font-family: 'SF-Pro-Display-Medium';
    font-size: 13px;
    line-height: 21px;
    color: #011a3c;
    text-transform: none;
}

.EnergyTab {
    padding: 50px 80px;
    .EnergyHeading {
        color: #011a3c;
        font-size: 15px;
        font-weight: 600;
    }
    .EnergyLightText {
        color: #233862cc;
        font-size: 14px;
        font-weight: 400;
    }

    @media screen and (max-width: 500px) {
        padding: 40px;
    }
}
.RatingContainer {
    margin: 25px 0px 35px 0px;
}
.ApplianceRating {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr;
    border-bottom: 1px solid #e5e5e5;
    padding: 20px 0px;
}
.textBold {
    color: #011a3c;
    font-weight: 500;
    font-family: 'SF-Pro-Display-Semibold';
}
