input:focus {
    outline: none;
    border: 1px solid var(--blue);
}

.InputWrapper {
    display: flex;
    align-items: center;
    width: 389px;
    height: 60px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    position: relative;
    margin-bottom: 20px;

    @media screen and (min-width: 1900px) {
        width: 33%;
    }

    @media screen and (max-width: 501px) {
        width: 100% !important;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding: 8px 0px;
        margin-left: -15px;
    }

    .ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
        left: 457px;
        top: 296px !important;
        border-radius: 23px;
    }

    .ant-select.SelectCountry.undefined.ant-select-single.ant-select-show-arrow.ant-select-disabled {
        @media screen and (max-width: 501px) {
            width: 80px !important;
        }
    }
}

.piiInputWrapper {
    @media screen and (max-width: 500px) {
        height: fit-content !important;
    }
}

.SelectCountry {
    border: 0px;

    img {
        position: relative;
        top: 2px;
    }

    @media screen and (max-width: 500px) {
        .ant-select-selection-search {
            left: 0 !important;
            top: 0 !important;
            right: 0 !important;
        }

        .ant-select-arrow {
            right: 10% !important;
            top: 48% !important;
        }
    }
}

.piiMobileSelectCountry {
    @media screen and (max-width: 500px) {
        width: 130px !important;
    }
}

.PhoneNumberDropdownInput {
    position: relative;
    border: 0px !important;
    padding-top: 10px;
    padding-right: 16px;
    width: 100%;
    margin-left: -14px;
    margin-top: 2.5px;
    background: var(--white);
    display: flex;
    right: 0;
    top: 2.5px;
    font-family: 'SF-Pro-Display-Light';
    color: #233862 !important;
    font-size: 16px;

    @media screen and (max-width: 600px) {
        min-width: 220px;
    }

    @media screen and (max-width: 500px) {
        min-width: 68%;
    }

    @media screen and (max-width: 380px) {
        min-width: 65%;
    }
}

.DropdownInput:focus {
    outline: none;
    border: 0px;
}

.LockIconWrapper {
    position: absolute;
    top: 23px;
    right: 25px;
    color: var(--blue-2);
    cursor: not-allowed;
}

.FloatingInput {
    position: absolute;
    pointer-events: none;
    top: 18px;
    transition: 0.2s ease all;
    height: 26px;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 26px;
    color: rgba(35, 56, 98, 0.78);

    @media screen and (max-width: 500px) {
        margin-top: -1px;
    }
}

.FloatingInputAlignSSE {
    left: 110px !important;

    @media screen and (max-width: 500px) {
        left: 70px !important;
    }
}

.FloatingInputAlign {
    left: 110px !important;

    @media screen and (max-width: 500px) {
        left: 70px !important;
    }
}

.sseLabel {
    left: 110px !important;

    @media screen and (max-width: 501px) {
        left: 70px !important;
    }
}

.CountryCode {
    font-family: 'SF-Pro-Display-Semibold';
    color: #233862;
    position: relative;
    top: -2px;
    margin-top: -6px;
    left: -7px;
}

.CountryCodeBox {
    width: 124px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 320px) {
        padding-left: 7px;
    }

    @media screen and (max-width: 500px) {
        width: 70px !important;
        text-align: center;
        justify-content: center;
        display: flex;
        margin: 8px 6px;
    }
}

.CountryFlag {
    @media screen and (max-width: 501px) {
        display: none;
    }
}

.ErrorText {
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #da1e28;
    margin-left: 20px;
}

input:focus~.FloatingInput,
input:disabled~.FloatingInput,
input:not(:focus):valid~.FloatingInput {
    top: 8px;
    margin-bottom: 10px;
    font-size: 11px;
    opacity: 1;
    font-family: 'SF-Pro-Display';
    font-size: 11px;
    line-height: 13px;
    color: var(--blue);
}

.countryCode {
    width: 150px;
    height: 17px;
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    line-height: 17px;
    color: #233862;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 55px;
    border: 0px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-left: 0px;
}

.ant-select-arrow {
    top: 55% !important;
    right: 21px;

    .anticon:not(.ant-select-suffix) {
        pointer-events: none;
        color: black;
    }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
    outline: none;
    border: 0px;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: rgba(0, 0, 0, 0.25);
    background: #fff;
    cursor: not-allowed;
}

.lock-icon {
    position: absolute;
    top: 20px;
    cursor: pointer;
}

.ant-select-dropdown.countrySelect.ant-select-dropdown-placement-bottomLeft {
    left: 457px;
    top: 296px !important;
    border-radius: 23px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    @media screen and (max-width: 500px) {
        padding: 0px;
    }
}

.FloatingInputRow {
    display: flex;
    gap: 4px;
}

.SelectCountryCode_FieldUpdateDot {
    background-color: #FFC222;
    width: 5.62px;
    height: 5.62px;
    border-radius: 50%;
    margin-top: 2px;
}