.TopRectangle {
    width: 410px;
    height: 151px;
    background: #f7f7f7;
    border-radius: 15px 15px 0px 0px;
    display: flex;
    flex-direction: column;
    font-family: 'SF-Pro-Display';
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 500px) {
        width: 100%;
    }
}

.StatusWrapper {
    display: flex;
    align-items: center;
}

.Title {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.14em;
    text-transform: uppercase;
    color: #000000;
    margin-right: 5px;
    margin-bottom: 0px;
}

.PaidStatus {
    width: 54px;
    height: 23px;
    background: #edfff5;
    border-radius: 3px;
    font-family: 'SF-Pro-Display-Bold';
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.055em;
    color: #32cc79;
    text-align: center;
    padding: 5px;
    margin-bottom: 0px;
}

.BuyoutStatus {
    width: 54px;
    height: 23px;
    background: rgba(229, 229, 229, 0.5);
    border-radius: 3px;
    font-family: 'SF-Pro-Display-Bold';
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.055em;
    color: rgba(35, 56, 98, 0.7);
    text-align: center;
    padding: 5px;
    margin-bottom: 0px;
}

.Subtitle {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 0px;
}

.BottomRectangle {
    display: flex;
    margin-top: 40px;
    font-family: 'SF-Pro-Display';
    padding-left: 70px;
}

.BottomRectangleTitle {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.055em;
    color: rgba(1, 26, 60, 0.85);
    text-transform: uppercase;
    margin-bottom: 0px;
}

.BottomRectangleSubTitle {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 13px;
    line-height: 25px;
    color: #011a3c;
}

.LeftWrapper {
    width: 50%;

    @media screen and (max-width: 500px) {
        width: 130px;
    }
}

.BtnWrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.CloseBtn {
    width: 123px;
    height: 50px;
    background: #e2eeff;
    border-radius: 30px;
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #004aad;
    border: none;
    cursor: pointer;
    margin-right: 20px;
}

.DownloadBtn {
    width: 123px;
    height: 50px;
    background: #004aad;
    border-radius: 30px;
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
    border: none;
    cursor: pointer;
}

.DownloadLink {
    color: #ffffff;
}

.DueStatus {
    width: 82px;
    height: 23px;
    background: #fff9e7;
    border-radius: 3px;
    font-family: 'SF-Pro-Display-Bold';
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.055em;
    color: #ffc222;
    text-align: center;
    padding: 5px;
}
