.page {
    max-width: 86%;
    margin: 30px auto;

    @media screen and (max-width: 600px) {
        margin: 0;
        max-width: none;
    }
}

.header {
    h5 {
        color: rgba(35, 56, 98, 0.88);
        font-size: 18px;
        line-height: 150%;
    }

    h4 {
        color: rgba(35, 56, 98, 0.88);
        font-family: var(--font-semibold);
        font-size: 22px;
        line-height: 140%; /* 30.8px */
    }

    @media screen and (max-width: 600px) {
        h5 {
            color: #011a3c;
            font-size: 14px;
            font-family: var(--font-bold);
            line-height: normal;
        }

        h4 {
            color: #011a3c;
            font-family: var(--font-regular);
            font-size: 16px;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 16px;
        }
    }
}

.overviewInfo {
    display: flex;
    gap: 8px;
    margin-top: 16px;

    & > div {
        border-radius: 14px;
        border: 1px solid #e2eeff;
        background: #f9fcff;
        box-shadow: none;
        padding: 16px;

        h4 {
            color: #3d5075;
            font-size: 12px;
            font-style: normal;
            font-family: var(--font-medium);
            line-height: 24px;
            letter-spacing: 0.78px;
            text-transform: uppercase;
        }

        p {
            color: var(--gray-scale-000000, #070c18);
            font-family: var(--font-bold);
            font-size: 18px;
            line-height: 24px;
            margin-top: 8px;
        }
    }
}

.iconDiv {
    width: 42px;
    height: 42px;
    border-radius: 100%;
    background-color: #e2eeff;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        margin: auto;
        width: 24px;
        height: 24px;
        stroke: #004aad;
    }
}

.mainContent {
    margin-top: 32px;

    .search {
        background-color: #004aad;
    }
}

.overviewTable {
    width: 100%;
    border-radius: 14px;
    border: 1px solid #e2eeff;
    background: #f9fcff;
    padding: 0px 29px;
}

.overviewTableHeaders {
    display: grid;
    padding: 38px 24px;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 12px;
    font-size: 13px;
    line-height: 16px;
    color: rgba(1, 26, 60, 0.8);
    width: 100%;

    span {
        color: rgba(35, 56, 98, 0.88);
        font-size: 12px;
        font-family: var(--font-medium);
        line-height: 24px; /* 200% */
        letter-spacing: 0.78px;
        text-transform: uppercase;
    }

    @media screen and (max-width: 600px) {
        display: none;
    }
}

.overviewTableBody {
    width: 100%;
    margin-top: -14px;
}

.overviewTableRow {
    margin: 14px 0;
    display: grid;
    padding: 16px 24px;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 12px;
    width: 100%;
    align-items: center;
    border-radius: 16px;
    background: #fff;

    @media screen and (max-width: 600px) {
        display: none;
    }

    p,
    span {
        color: #000;
        font-size: 16px;
        line-height: 150%; /* 24px */
    }

    button {
        padding: 14px 16px;
        border: 0.5px solid #004aad;
        border-radius: 30px;
        font-family: var(--font-bold);
        background-color: #ffffff !important;
        span {
            color: #004aad;
            font-size: 13px;
            line-height: normal;
        }

        &:hover {
            span {
                color: #fff;
            }
        }
    }
}

.profileType {
    text-transform: lowercase;

    &:first-letter {
        text-transform: capitalize;
    }
}

.clusterNameAndLogo {
    display: flex;
    gap: 8px;
    align-items: center;

    img {
        width: 42px;
        height: 42px;
        border-radius: 100%;
    }
}

.paginationContainer {
    text-align: center;
    margin-top: 42px;
}

.mobileCard {
    padding: 18px 11px;
    display: flex;
    flex-direction: column;
    gap: 58px;
    border-radius: 7px;
    background: #fff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.06);
    margin-bottom: 18px;

    & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .clusterNameAndLogo {
        p {
            color: #3d5075;
        }
    }

    img {
        width: 25.38px;
        height: 25.38px;
    }

    p {
        color: #011a3c;

        font-size: 12px;
        font-weight: 400;
        line-height: 150%; /* 18px */
    }

    .profileTypeContainer {
        p:first-of-type {
            color: #3d5075;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 166.667% */
            letter-spacing: 1.02px;
            text-transform: uppercase;
        }
    }

    button {
        width: 103px !important;
        height: auto !important;
        padding: 8px 12px !important;
        span {
            font-size: 12px;
        }
    }
}

.MobileSummary {
    height: auto;

    .slick-slide {
        background-color: #000;
    }

    & > div {
        height: 135px;
    }

    & > div > div {
        display: flex;
    }

    & > div > div > div {
        width: 240px !important;
        margin-right: 8px;

        &:last-of-type {
            max-width: 25%;
            width: 100% !important;

            & > div > div {
                width: 98%;
            }
        }
    }

    & > div > div > div > div > div {
        width: 240px;
        height: 135px;
        padding: 16px;
        border-radius: 14px;
        border: 1px solid var(--shades-and-tints-primary-e-2-eeff, #e2eeff);
        background: #f9fcff;
        gap: 10px;

        h4 {
            color: #3d5075;
            font-family: var(--font-medium);
            font-size: 12px;
            line-height: 24px; /* 200% */
            letter-spacing: 0.78px;
            text-transform: uppercase;
        }

        p {
            color: #070c18;
            font-size: 18px;
            font-family: var(--font-semibold);
            line-height: 24px; /* 133.333% */
        }
    }

    ul {
        bottom: -22px;
        li.slick-active {
            width: 30px;
        }

        li {
            width: 15px;
            margin: 0 3.5px;
        }

        li button:before {
            content: '__';
            color: #e2eeff;
            opacity: 1;
            font-size: 20px;
            line-height: 16px;
            font-family: 'SF-Pro-Display-Bold';
        }
    }
}

.Card {
    display: inline-grid;
    grid-row-gap: 40px;
    padding: 25px 22px 28px 21px;
    width: 100%;
    height: 178px;
    background: #ffffff;
    border-radius: 7px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    font-family: 'SF-Pro-Display';
    margin: 0 13px 14px 0;
}
