.formWrapper {
    margin: 24px 42px;

    @media only screen and (max-width: 500px) {
        margin: 20px;
    }
}

.formSection {
    > h3 {
        font: 12px/14px var(--font-regular);
        color: #011a3c;
        margin: 0;
    }

    > p {
        font: 14px/18px var(--font-light);
        color: #4d4d4d;
        margin: 0 0 14px 0;
    }
}

.maintenanceIcon {
    width: 190px;
    height: 190px;
}

.successIcon {
    width: 120px;
    height: 120px;
    margin-bottom: 22px;
}

.compareTenures {
    margin: 24px 42px;

    > p {
        font: 14px/24px var(--font-regular);
        margin-bottom: 24px;
        color: #011a3c;
    }

    @media only screen and (max-width: 500px) {
        margin: 20px;
    }
}

.tenureCard {
    border-radius: 12px;
    margin-bottom: 24px;
    padding: 16px;

    &.tenureCard_Current {
        border: 1px solid #f3f3f3;
        background-color: #fafafa;

        .tenureCard_Title {
            background-color: #e5e5e580;
            color: #233862b2;
        }
    }

    &.tenureCard_New {
        border: 1px solid #e2eeff;
        background-color: #f9fcff;

        .tenureCard_Title {
            background-color: #e2eeff;
            color: #004aad;
        }
    }
}

.tenureCard_Title {
    text-transform: uppercase;
    padding: 1.5px 8px;
    font: 12px/20px var(--font-semibold);
    letter-spacing: 1px;
    margin: 0;
    border-radius: 3px;
}

.tenureCard_Row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
        > span {
            font: 10px/100% var(--font-medium);
            color: #011a3cd9;
            margin-bottom: 8px;
            text-transform: uppercase;
        }

        > p {
            font: 14px/120% var(--font-bold);
            color: #070c18;
            margin: 0;
        }
    }
}
