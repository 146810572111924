.wrapper {
    display: flex;
    justify-content: space-between;
    width: 1152px;

    @media screen and (max-width: 600px) {
        max-width: 325px;
        justify-content: center;
    }

    @media screen and (max-width: 1200px) {
        border-radius: 24px;
        width: 100% !important;
    }
}

.cityImgWrapper {
    width: 500px;
    height: 436px;
    background-color: #004aad;
    border-radius: 0px 15px 15px 0px;
    position: relative;

    .solarCityImg {
        width: 500px;
        object-fit: contain;
    }

    .closeIcon {
        position: absolute;
        top: 0;
        right: 24px;
        top: 21px;
        cursor: pointer;
    }

    @media screen and (max-width: 1200px) {
        display: none;
    }
}

.left {
    padding: 76px 40px 0px 42px;
    width: 540px;

    > img {
        width: 245px;
        height: 43px;
        object-fit: contain;

        @media screen and (max-width: 576px) {
            max-width: 175px;
            width: auto;
        }
    }

    > h2 {
        font: 22px/35px var(--font-bold);
        color: #233862;
        margin: 32px 0 6px 0;

        @media screen and (max-width: 576px) {
            font-size: 16px;
            line-height: normal;
        }
    }

    > p {
        font: 16px/24px var(--font-light);
        color: #5f5b5b;
        margin-bottom: 29px;

        @media screen and (max-width: 576px) {
            font-size: 14px;
            line-height: 23px;
        }
    }

    @media screen and (max-width: 600px) {
        width: auto;
        padding: 24px 0px 0px 0px;
        max-width: 80%;
    }
}

.btnAndCta {
    display: grid;
    grid-template-columns: 166px 1fr;
    column-gap: 16px;

    @media screen and (max-width: 576px) {
        grid-template-columns: 1fr;
        justify-content: center;

        & > button {
            margin: 0px auto;
        }

        & > div {
            justify-content: center;
            margin: 26px 0;
        }
    }
}

@media screen and (max-width: 600px) {
    .modalClass {
        top: 5%;
        border-radius: 24px;
        height: 475px;
        right: 0;
        left: 0;
        margin: auto;
        width: 100%;
        max-width: 356px;
        overflow: hidden;
    }
}

.modalHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: white;
    background: url(../../../../../assets/images/solar-city-bg.svg) #004aad 116%
        42% / 200px no-repeat;

    h6 {
        color: white;
        font-size: 14px;
        font-family: var(--font-bold);
        line-height: 120%;
        margin-bottom: 3px;
    }

    p {
        font-size: 12px;
        line-height: 150%;
    }

    div {
        max-width: 250px;
        padding: 28px 0px;
        margin-left: 42px;
    }

    .solarCityImg {
        max-width: 100px;
    }
}

div.modalHeader {
    @media screen and (max-width: 600px) {
        padding-bottom: 0 !important;
        background-position-x: 130%;
        background-size: 180px;

        div {
            margin-left: 36px;
            width: 205px;
            padding: 18.5px 0;
        }

        h6,
        p {
            line-height: 20px;
        }
    }
}

.modalContent {
    padding: 24px 42px;

    h2 {
        color: #004aad;
        font-family: var(--font-bold);
        font-size: 18px;
        line-height: 24px;
    }

    p {
        color: #5f5b5b;
        font-size: 14px;
        line-height: 150%;
    }

    @media screen and (max-width: 600px) {
        padding: 24px 36px;

        h2 {
            color: #233862;
            font-size: 18px;
            line-height: 24px;
        }

        p {
            color: #4d4d4d;
            font-family: var(--font-light);
            font-size: 12px;
            line-height: 20px;
        }
    }
}

div.modalContent {
    @media screen and (max-width: 600px) {
        padding-bottom: 36px !important;
    }
}
