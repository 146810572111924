.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 95px;
    background: #fafafa;
    padding: 30px 25px;
    position: relative;
    z-index: 2;

    @media only screen and (max-width: 800px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: unset;
        gap: 36px;
        height: fit-content;
    }

    @media only screen and (min-width: 1024px) {
        padding: 0 52px;
    }

    @media only screen and (min-width: 1300px) {
        padding: 0 120px;
    }

    @media only screen and (min-width: 1440px) {
        padding: 0 149px;
    }
}

.links {
    display: flex;
    align-items: center;
    gap: 37px;

    a {
        font: 14px/24px 'SF-Pro-Display';
        color: #4d4d4d;

        &:hover,
        &:visited {
            color: #4d4d4d !important;
        }
    }

    @media only screen and (min-width: 801px) {
        a {
            font: 16px/29.75px 'SF-Pro-Display';
        }
    }
}

.right {
    display: flex;
    align-items: center;
    gap: 98px;

    p {
        font: 14px/24px 'SF-Pro-Display';
        color: #5f5b5b;
        margin: 0;
    }

    @media only screen and (max-width: 800px) {
        gap: 28px;
    }

    @media only screen and (max-width: 500px) {
        gap: 40px;
    }
}

.socials {
    display: flex;
    align-items: center;
    gap: 10px;

    @media only screen and (max-width: 500px) {
        gap: 7px;
    }
}


.logo {
    display: none;
    width: 98px;
    height: 31px;
    object-fit: cover;

    @media only screen and (min-width: 800px) {
        display: block;
    }
}