.AppButton {
    // @media screen and (min-width: 1922px) {
    //   font-size: calc(15px + 5px) !important;
    //   width: calc(170px + 70px) !important;
    //   height: calc(50px + 20px) !important;
    //   border-radius: 40px !important;
    // }
}

.AppButtonOutline {
    // @media screen and (min-width: 1922px) {
    //   font-size: calc(15px + 5px) !important;
    //   width: calc(170px + 70px) !important;
    //   height: calc(50px + 20px) !important;
    //   border-radius: 40px !important;
    // }
}

.AppButtonOutline:hover {
    box-shadow: 0.5px 1px 5px #b9bbbe9f !important;
    background-color: rgb(0, 74, 173) !important;
    color: white !important;
}

.AppButtonOutlineNoShadow {
    &:hover,
    &:active,
    &:focus {
        background-color: #004aad !important;
        color: #ffffff !important;
    }
}

.AppButtonOutline:focus {
    background-color: none !important;
    color: none !important;
}

.AppButtonWhite {
    // @media screen and (min-width: 1922px) {
    //   font-size: calc(15px + 5px) !important;
    //   width: calc(170px + 70px) !important;
    //   height: calc(50px + 20px) !important;
    //   border-radius: 40px !important;
    // }
}

.AppButtonWhite:hover {
    background-color: var(--white) !important;
    box-shadow: 0.5px 1px 5px #b9bbbe9f !important;
    color: #0458c9 !important;
}

.AppButtonWhite:focus {
    background-color: var(--white) !important;
    color: #013d8c !important;
}

.AppButton:hover {
    background-color: #0458c9 !important;
    color: #fff !important;
}

.AppButton:focus {
    background-color: #013d8c !important;
    color: #fff !important;
}

.AppButtonNoFocus {
    &:hover {
        background-color: #0458c9 !important;
        color: #fff !important;
    }

    &:focus {
        background-color: none !important;
        color: none !important;
    }
}

.ButtonSmall {
    // @media screen and (min-width: 1922px) {
    //   font-size: calc(15px + 5px) !important;
    //   width: calc(170px + 70px) !important;
    //   height: calc(50px + 20px) !important;
    //   border-radius: 40px !important;
    // }

    width: 100px;
    height: 50px;
    background-color: var(--white);
}

.ButtonSmall:hover {
    color: var(--white) !important;
    background-color: var(--blue) !important;
}

.AppButtonStyle:hover {
    background-color: var(--blue) !important;
    color: var(--white) !important;
}

.AppButtonStyleTwo:hover {
    background-color: #e2eeff !important;
}

.AppButtonStylePrimary {
    background-color: #004aad !important;
    border-radius: 30px;
    font-family: 'SF-Pro-Display-Semibold !important';
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #ffffff !important;

    &:hover {
        background-color: #00439a !important;
    }
}

.AppButtonStyleSecondary {
    background-color: #e2eeff !important;
    border-radius: 30px;
    font-family: 'SF-Pro-Display-Semibold !important';
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #004aad !important;

    &:hover {
        background-color: #d6e6fc !important;
    }
}

.ButtonPrimaryXSmall {
    background-color: #e2eeff !important;
    border-radius: 30px;
    font-family: 'SF-Pro-Display-Medium !important';
    font-size: 14px;
    padding: 11px 22px !important;
    width: fit-content !important;
    height: 37px !important;
    line-height: 17px;
    text-align: center;
    color: #004aad !important;

    &:hover,
    &:active,
    &:focus {
        background-color: #004aad !important;
        color: #ffffff !important;
    }
}

.mainButtonNoBg {
    border-radius: 30px;
    font-family: 'SF-Pro-Display-Medium !important';
    font-size: 12px !important;
    height: 37px !important;
    line-height: 17px;
    text-align: center;
    color: #004aad !important;

    &:hover {
        background-color: #e2eeff !important;
    }
}

.ConsumerHoverStyle {
    &:hover,
    &:active,
    &:focus {
        background-color: #004aad !important;
        color: #ffffff !important;

        svg {
            fill: #ffffff;
            stroke: #ffffff;
        }
    }
}

.ConsumerHoverNoFillStyle {
    gap: 6px;

    svg {
        stroke: #004aad;
        flex-shrink: 0;
    }

    &:hover {
        background-color: #004aad !important;
        color: #ffffff !important;

        svg {
            stroke: #ffffff;
        }
    }
}

.ConsumerSecondaryHoverStyle {
    &:hover,
    &:active,
    &:focus {
        background-color: #002c68 !important;
        color: #ffffff !important;
    }
}

.ConsumerTertiaryHoverStyle {
    &:hover,
    &:active,
    &:focus {
        background-color: #f9fcff !important;
        color: #004aad !important;
    }
}

.ConsumerPrimaryHoverStyle {
    &:hover,
    &:active,
    &:focus {
        background-color: #0458c9 !important;
        color: #ffffff !important;
    }
}

.btnV2 {
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    box-shadow: none;
    box-sizing: border-box;
    display: grid;
    grid-template-areas: 'stack';
    place-items: center;
    min-width: 90px;
    flex-shrink: 0;

    &:focus {
        outline: none;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.7;
    }

    > * {
        grid-area: stack;
    }
}

.btnContent {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex-wrap: nowrap;
    white-space: nowrap;
}

.BtnV2ExtraSmall {
    font: 12px/12px var(--font-medium);
    padding: 0 12px;
    height: 30px;
    border-radius: 30px;
}

.BtnV2Small {
    font: 13px/13px var(--font-semibold);
    padding: 0 16px;
    height: 44px;
    border-radius: 30px;
}

.BtnV2Medium {
    font: 14px/14px var(--font-semibold);
    padding: 0 24px;
    height: 49px;
    border-radius: 30px;
}

.BtnV2Large {
    font: 14px/14px var(--font-semibold);
    padding: 0 32px;
    height: 57px;
    border-radius: 30px;
}

.BtnV2Primary {
    background-color: #004aad;
    color: #ffffff;

    &.btnV2StrokeIcon > .btnContent > svg {
        stroke: #ffffff;
    }

    &.btnV2FillIcon > .btnContent > svg {
        fill: #ffffff;
    }

    &:hover {
        box-shadow: 0px 7.71px 48.84px 0px #0000001a;
    }

    &:active {
        background-color: #002c68;
    }

    &:disabled {
        &:hover {
            box-shadow: none;
        }

        &:active {
            background-color: #004aad;
        }
    }
}

.BtnV2Secondary {
    background-color: #f9fcff;
    border: 1.29px solid #004aad;
    color: #004aad;

    &.btnV2StrokeIcon > .btnContent > svg {
        stroke: #004aad;
    }

    &.btnV2FillIcon > .btnContent > svg {
        fill: #004aad;
    }

    &:hover {
        background-color: #004aad;
        color: #ffffff;

        &.btnV2StrokeIcon > .btnContent > svg {
            stroke: #ffffff;
        }

        &.btnV2FillIcon > .btnContent > svg {
            fill: #ffffff;
        }
    }

    &:active {
        border: 1.29px solid #f9fcff;
        background-color: #f9fcff;
        color: #002c68;

        &.btnV2StrokeIcon > .btnContent > svg {
            stroke: #002c68;
        }

        &.btnV2FillIcon > .btnContent > svg {
            fill: #002c68;
        }
    }

    &:disabled {
        border: 1.29px solid #f9fcff;
        background-color: #f9fcff;

        &:hover {
            color: #004aad;
        }

        &:hover,
        &:active {
            &.btnV2StrokeIcon > .btnContent > svg {
                stroke: #004aad;
            }
        }
    }
}

.BtnV2Tertiary {
    background-color: transparent;
    color: #004aad;

    &.btnV2StrokeIcon > .btnContent > svg {
        stroke: #004aad;
    }

    &.btnV2FillIcon > .btnContent > svg {
        fill: #004aad;
    }

    &:hover {
        background-color: #f9fcff;
    }

    &:active {
        background-color: transparent;
        color: #002c68;

        &.btnV2StrokeIcon > .btnContent > svg {
            stroke: #002c68;
        }

        &.btnV2FillIcon > .btnContent > svg {
            fill: #002c68;
        }
    }

    &:disabled {
        background-color: transparent;

        &:active {
            color: #004aad;

            &.btnV2StrokeIcon > .btnContent > svg {
                stroke: #004aad;
            }

            &.btnV2FillIcon > .btnContent > svg {
                fill: #004aad;
            }
        }
    }
}

.editClass {
    .btnContent > svg {
        width: 14px;
        height: 14px;
    }
}

.addClass {
    .btnContent > svg {
        width: 16px;
        height: 16px;
    }
}
