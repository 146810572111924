.ASOContentWrapper {
    margin: 49px 20px;

    @media only screen and (min-width: 1024px) {
        margin-right: 52px;
        margin-left: 52px;
    }

    @media only screen and (min-width: 1300px) {
        margin: 20px 80px 20px 54px !important;
    }

    @media only screen and (min-width: 1440px) {
        margin: 52px 150px;
    }

    @media only screen and (min-width: 1600px) {
        width: 1500px !important;
        max-width: 1500px !important;
        margin: 52px auto !important;
    }
}

.ASOMidContent {
    display: flex;
    align-items: flex-start;
    gap: 22px;
    margin-top: 24px;

    @media only screen and (max-width: 800px) {
        flex-direction: column-reverse;
        gap: 36px;
    }
}

.ASONavWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 101px;
    padding: 0 54px 0 54px;
    background: #ffffff;
    border-bottom: 1px solid #e2eeff;
    position: sticky;
    top: 0;
    z-index: 100;

    .ASONavLeft {
        h1 {
            font: 14px/24px 'SF-Pro-Display';
            color: #070c18;
            margin-bottom: 3px;
        }

        p {
            font: 12px/20px 'SF-Pro-Display';
            color: #4d4d4d;
        }
    }

    .ASONavRight {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        p {
            font: 14px/17px 'SF-Pro-Display-Semibold';
            color: #004aad;
        }

        .LoadingWrapper {
            overflow: visible;
        }

        &.ASONavRight_Disabled {
            cursor: not-allowed;
            background-color: #f9fcff;
            padding: 17px 24px;
            border-radius: 30px;
        }
    }

    @media only screen and (max-width: 1024px) {
        padding: 0 20px 0 20px;
    }

    @media only screen and (max-width: 500px) {
        flex-direction: column;
        width: 100%;
        height: fit-content;
        padding: 17px 0 31px 0;
        margin: 0 auto;
        justify-content: center;
        gap: 14px;
        border-bottom: none;
        box-shadow: 0px 0px 18.8182px rgba(0, 0, 0, 0.06);

        .ASONavLeft {
            text-align: center;

            h1 {
                font: 12px/24px 'SF-Pro-Display';
                margin-bottom: 0;
            }

            p {
                font-family: 'SF-Pro-Display-Light';
            }
        }
    }

    &.ASONavWrapper_RowOnMobile {
        @media only screen and (max-width: 500px) {
            flex-direction: row;
            justify-content: space-between;
            padding: 16px 20px;
        }
    }
}

.ASONavWrapper_Admin {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 101px;
    padding: 0 80px 0 54px;
    background: #ffffff;
    position: sticky;
    top: 0;
    z-index: 100;

    > img {
        width: 98px;
        height: 31px;
        object-fit: cover;
    }

    @media only screen and (max-width: 500px) {
        padding: 0 16px;
    }
}

.ASONavWrapper_Admin_NavBar_Menu {
    li {
        display: flex;
        align-items: center;
    }
}

.ASOTopColumn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 800px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: unset;
        gap: 42px;
    }
}

.ASOGoBackBtn {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    span {
        font: 14px/17px 'SF-Pro-Display-Medium';
        color: #004aad;
    }
}

.ASOProgress {
    margin-left: auto;

    p {
        font: 14px/17px 'SF-Pro-Display-Medium';
        color: #4d4d4d;
        margin-bottom: 8px;
        text-align: right;
    }

    .ant-progress.ant-progress-line.ant-progress-status-normal.ant-progress-default,
    .ant-progress.ant-progress-line.ant-progress-status-success.ant-progress-default {
        width: 400px;
    }

    @media only screen and (max-width: 800px) {
        width: 100%;

        p {
            font: 12px/20px 'SF-Pro-Display';
            margin-bottom: 0;
        }

        .ant-progress.ant-progress-line.ant-progress-status-normal.ant-progress-default,
        .ant-progress.ant-progress-line.ant-progress-status-success.ant-progress-default {
            width: 100%;
        }
    }
}

.ASOSelectedSolutionWrapper {
    background: #f9fcff;
    padding: 34px;
    width: 463px;

    @media only screen and (max-width: 800px) {
        width: 100%;
        padding: 35px 16px;

        .ASOSelectedSolutionContent {
            > div {
                margin-bottom: 24px;
            }
        }
    }
}

.ASOSelectedSolutionHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
        font: 12px/24px 'SF-Pro-Display-Medium';
        color: #000000cc;
    }

    @media only screen and (max-width: 800px) {
        flex-direction: column;
        justify-content: unset;
        text-align: center;
        gap: 20px;

        h3 {
            letter-spacing: 0.065em;
            margin-bottom: 0;
        }
    }
}

.ASOTextIconBtn {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 8px;

    p {
        font: 12px/14px 'SF-Pro-Display-Medium';
        color: #004aad;
    }

    svg {
        stroke: #004aad;
    }

    &:hover {
        background: #004aad;
        border-radius: 30px;

        p {
            color: #ffffff;
        }

        svg {
            stroke: #fff;
        }
    }
}

.ASOSelectedSolutionContent {
    background: #ffffff;
    margin-top: 11px;
    overflow: hidden;
    position: relative;

    img {
        width: 395px;
        height: 152px;
        object-fit: cover;
        margin-bottom: 18px;
        border-radius: 4px 4px 0 0;
    }

    > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 22px 18px 22px;
    }

    @media only screen and (max-width: 800px) {
        img {
            width: 100%;
            height: 100%;
            border-radius: 12px;
        }

        > div {
            margin: 0 18px 24px 18px;
        }

        .ASOSelectedSolutionContent_Heading {
            align-items: flex-start;
        }

        > div:last-child {
            margin-bottom: 0;
        }
    }
}

.ASOSelectedSolutionContentText {
    p {
        font: 14px/24px 'SF-Pro-Display';
        color: #070c18;
        margin-bottom: 4px;
    }

    h4 {
        font: 14px/24px 'SF-Pro-Display-Bold';
        color: #070c18;
        margin: 0;
    }

    span {
        font: 12px/20px 'SF-Pro-Display';
        color: #233862;
        margin-bottom: 8px;
    }

    h6 {
        font: 14px/24px 'SF-Pro-Display';
        color: #070c18;
        margin: 0;
    }

    h5 {
        font: 14px/24px 'SF-Pro-Display-Bold';
        color: #070c18;
        margin: 0;
    }

    &.Right {
        text-align: right;
    }

    @media only screen and (max-width: 500px) {
        p {
            max-width: 129px;
        }

        span {
            color: #b0b0b0;
        }
    }
}

.ASOSelectedSolution_Flag {
    position: absolute;
    top: 15px;
    right: 0;
    padding: 1.5px 8px;
    border-radius: 3px;

    span {
        font: 12px/20px 'SF-Pro-Display-Semibold';
        color: #ffffff;
        letter-spacing: 0.085em;
        margin: 0;
    }
}

.Approved {
    background: #32cc79;
}

.Disputed {
    background: #d2a735;
    display: flex;
    align-items: center;
    gap: 4px;

    .tooltip {
        width: 15px;
        height: 15px;
        background-color: white;
        border-radius: 100%;
        padding: 3px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    svg {
        path {
            stroke: #d2a735;
        }
    }
}

.ASOSeePackageBtn {
    font: 12px/14px 'SF-Pro-Display-Semibold';
    color: #004aad;
    text-decoration: underline;
    text-underline-offset: 3px;
    cursor: pointer;

    @media only screen and (max-width: 500px) {
        margin-top: 5px;
    }
}

.ASOEnergyInfoWrapper {
    background: #f9fcff;
    padding: 22px 26px;
    width: 463px;
    margin-top: 24px;
    border-radius: 4px;

    > div {
        display: flex;
        align-items: center;
    }

    @media only screen and (max-width: 800px) {
        width: 100%;
        padding: 12px 20px 20px 19px;
    }
}

.ASOEnergyInfoHeading {
    justify-content: space-between;
    margin-bottom: 20px;

    h4 {
        font: 12px/24px 'SF-Pro-Display-Medium';
        color: #000000cc;
        margin: 0;
    }
}

.ASOEnergyInfoList {
    gap: 22px;

    div {
        h5 {
            font: 12px/24px 'SF-Pro-Display';
            color: #3d5075;
            margin-bottom: 0;
        }

        p {
            font: 14px/26px 'SF-Pro-Display';
            color: #233862;
            margin-bottom: 0;
        }
    }

    @media only screen and (max-width: 500px) {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);

        div:nth-child(2n) {
            text-align: right;
        }
    }
}

.ASOCreateAccount {
    background: #f9fcff;
    padding: 43px 30px 54px 30px;
    width: 656px;
    border-radius: 4px;

    h2 {
        font: 18px/24px 'SF-Pro-Display-Bold';
        color: #233862;
        margin-bottom: 5px;
    }

    p {
        font: 14px/24px 'SF-Pro-Display';
        color: #4d4d4d;
        margin-bottom: 22px;
        max-width: 423px;
    }

    &.ASOCreateAccount_DEPConsumer {
        padding-bottom: 76px;
    }

    &.ASOCreateAccount_OutrightSale {
        padding-bottom: 0;
    }

    @media only screen and (max-width: 800px) {
        width: 100%;
        padding: 33px 25px;

        &.ASOCreateAccount_DEPConsumer {
            padding-bottom: 33px;
        }

        h2 {
            font: 14px/20px 'SF-Pro-Display-Bold';
        }

        p {
            font: 12px/20px 'SF-Pro-Display';
        }
    }

    @media only screen and (min-width: 1300px) {
        width: 100%;
    }
}

.ASOCreateAccountStep {
    background: #ffffff;
    padding: 30px 28px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    cursor: pointer;
    border: 1px solid #e2eeff;
    border-radius: 4px;

    img {
        margin-left: auto;
    }

    @media only screen and (max-width: 800px) {
        padding: 20px;
    }

    &.ASOCreateAccountStep_DEP {
        padding: 24px 28px;
    }
}

.ASOCreateAccountStep_Icons {
    width: 38px;
    height: 38px;
    position: relative;

    svg {
        flex-shrink: 0;
    }

    .ASOCreateAccountStep_Icons_Check {
        width: 13px;
        height: 13px;
        position: absolute;
        top: 0;
        right: 0;
    }
}

.ASOCreateAccountStepText {
    margin-left: 20px;

    p {
        font: 14px/24px 'SF-Pro-Display';
        color: #5f5b5b;
        margin-bottom: 0;
    }

    @media only screen and (max-width: 1120px) {
        margin-left: 12px;

        p {
            font: 12px/20px 'SF-Pro-Display';
            margin-right: 6px;
        }
    }
}

.ASOCreateAccountStepText_Heading {
    display: flex;
    align-items: center;
    gap: 10px;

    h5 {
        font: 16px/24px 'SF-Pro-Display';
        color: #233862;
        margin-bottom: 0;
    }

    > div {
        padding: 1.5px 8px;
        background: #fffaec;
        border-radius: 3px;

        span {
            font: 12px/20px 'SF-Pro-Display-Semibold';
            color: #e4aa0f;
            letter-spacing: 0.085em;
        }
    }

    @media only screen and (max-width: 1120px) {
        flex-direction: column-reverse;
        align-items: flex-start;

        h5 {
            font: 14px/23px 'SF-Pro-Display';
        }

        > div {
            left: -48px;
            width: fit-content;

            span {
                font-size: 10px;
            }
        }
    }
}

.ASOCreateAccountSwitch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 45px;

    p {
        font: 14px/24px 'SF-Pro-Display';
        color: #001837;
        margin: 0;
    }

    @media only screen and (max-width: 800px) {
        flex-direction: column;
        justify-content: unset;
        gap: 7px;

        p {
            font: 12px/20px 'SF-Pro-Display';
            text-align: center;
        }
    }
}

.ASONavButtons {
    display: flex;
    align-items: center;
}

.ASONavButtons_IconBtn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #f9fcff;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 24px;
        height: 24px;
    }

    &.SmallIcon {
        background-color: transparent;
        width: fit-content;
        height: fit-content;

        svg {
            width: 16px;
            height: 16px;
        }
    }

    &.ASONavButtons_IconBtn_Disabled {
        cursor: not-allowed;
        background-color: #f9fcff;
        opacity: 70%;
    }
}

.ASONav_SaveText_Disabled {
    opacity: 70% !important;
}

.ASONavButtons_Divider {
    height: 29px;
    width: 0.5px;
    background-color: #e2eeff;
    margin: 0 19px;

    @media only screen and (max-width: 500px) {
        margin: 0 17px;
    }
}
.ASOSelectedSolutionContainer_DEP {
    display: flex;
    column-gap: 28px;

    .ASOTextIconBtn {
        border-radius: 30px;
        border: 0.5px solid #004aad;
        flex-basis: 50%;
        height: fit-content;
        justify-content: center;

        @media screen and (max-width: 600px) {
            flex-basis: 53%;
        }
    }
}

.ASOSelectedSolutionHeading_DEP {
    font: 14px/24px 'SF-Pro-Display-Light';
    color: #000000cc;
    margin-bottom: 16px;

    @media screen and (max-width: 600px) {
        line-height: 20px;
    }
}

.ASOApplianceInfoWrapper {
    background-color: #e2eeff;
    padding: 16px 54px 35px 34px;
    border-radius: 0 0 4px 4px;
    width: 463px;

    h4 {
        font: 14px/24px 'SF-Pro-Display-Medium';
        color: #000000cc;
        margin-bottom: 7px;
    }

    @media only screen and (max-width: 500px) {
        padding: 22px 20px 22px 17px;
        width: 100%;

        h4 {
            font: 12px/20px 'SF-Pro-Display-Medium';
            margin-bottom: 5px;
        }
    }
}

.ASO_Package_Drawer {
    .ant-drawer-body {
        padding: 69px 35px 16px 34px;

        &::-webkit-scrollbar {
            display: none;
        }

        & {
            scrollbar-width: none;
        }

        @media only screen and (max-width: 500px) {
            padding: 30px 28px;
            margin-bottom: -100px;
        }
    }
}

.ASO_Package_Drawer_Content {
    h2 {
        font: 18px/24px 'SF-Pro-Display-Bold';
        color: #004aad;
        margin-bottom: 8px;
    }

    h3 {
        font: 14px/24px 'SF-Pro-Display';
        color: #5f5b5b;
        margin-bottom: 23px;
    }

    @media only screen and (max-width: 500px) {
        h2 {
            font: 14px/20px 'SF-Pro-Display-Semibold';
        }

        h3 {
            font-size: 12px;
        }
    }
}

.ASO_Package_Drawer_Content_Dropdowns {
    background: #f9fcff;
    padding: 25px 34px 42px 34px;
    border-radius: 4px;

    h3 {
        font: 16px/24px 'SF-Pro-Display-Light';
        color: #000000cc;
        margin-bottom: 11px;
    }

    @media only screen and (max-width: 500px) {
        padding: 35px 28px 83px 28px;

        h3 {
            font: 12px/20px 'SF-Pro-Display';
            margin-bottom: 16px;
        }
    }
}

.ASO_Package_Drawer_Dropdown {
    border: 1px solid #e2eeff;
    padding: 30px 24px;
    background: #ffffff;

    @media only screen and (max-width: 500px) {
        padding: 18px;
    }
}

.ASO_Package_Drawer_Dropdown_Top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
        h4 {
            font: 16px/24px 'SF-Pro-Display';
            color: #233862;
            margin-bottom: 3px;
        }

        p {
            font: 14px/24px 'SF-Pro-Display';
            color: #5f5b5b;
        }
    }

    img {
        width: 26px;
        height: 26px;
        cursor: pointer;
    }

    @media only screen and (max-width: 500px) {
        align-items: flex-start;

        > div {
            h4 {
                font: 14px/23px 'SF-Pro-Display';
            }

            p {
                font: 12px/20px 'SF-Pro-Display';
            }
        }
    }
}

.ASO_Package_Drawer_Dropdown_Bottom {
    height: fit-content;
    margin-top: 10px;

    .APDDB {
        background: #f9fcff;
        padding: 48px 14.5px;
        border-radius: 4px;

        h4 {
            font: 12px/20px 'SF-Pro-Display';
            color: #000000cc;
            margin-bottom: 6px;
        }
    }

    @media only screen and (max-width: 500px) {
        .APDDB {
            padding: 26px 8px;
            background: #e2eeff;
        }

        .ASOSelectedSolutionContent {
            > div {
                margin: 0 0 46px 0;
            }
        }
    }
}

.ASO_Package_Drawer_Dropdown_Bottom_Closed {
    opacity: 0;
    visibility: hidden;
    height: 0;
    transition: ease 0.3s;
    margin: 0;
}

.ASO_Package_Drawer_Dropdown_Bottom_Open {
    opacity: 1;
    visibility: visible;
    transition: ease 0.3s;
}

.ASO_Package_Drawer_Dropdown_Icon_Open {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}

.ASO_Package_Drawer_Dropdown_Icon_Closed {
    transition: transform 0.3s ease;
}

.ASO_Package_Drawer_ProviderDetails {
    background: #f9fcff;
    padding: 18px 30px 33px 28px;
    border-radius: 4px;

    @media only screen and (max-width: 500px) {
        padding: 22px 18px;
    }
}

.ASO_Package_Drawer_ProviderDetails_Top {
    display: flex;
    align-items: center;
    gap: 16px;

    div:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 63px;
        height: 63px;
        flex-shrink: 0;
        background: #e2eeff;
        border-radius: 50%;

        span {
            font: 19px/24px 'SF-Pro-Display';
            color: #3d5075;
        }
    }

    div:last-child {
        h4 {
            font: 18px/24px 'SF-Pro-Display';
            color: #3d5075;
            margin: 0;
        }

        p {
            font: 12px/20px 'SF-Pro-Display';
            color: #3d5075;
        }
    }

    @media only screen and (max-width: 500px) {
        div:first-child {
            width: 56px;
            height: 56px;

            span {
                font-size: 16px;
            }
        }

        div:last-child {
            h4 {
                font: 16px/20px 'SF-Pro-Display';
            }
        }
    }
}

.ASO_Package_Drawer_ProviderDetails_Bottom {
    margin-top: 28px;

    > div {
        display: flex;
        align-items: center;
        gap: 8px;
        height: 42px;
        box-shadow: 0 1px 0 rgba(176, 176, 176, 0.2);

        img {
            width: 20px;
            height: 20px;
            flex-shrink: 0;
        }

        h4 {
            font: 14px/24px 'SF-Pro-Display';
            color: #3d5075;
            margin: 0;
        }
    }

    > div:last-child {
        box-shadow: none;
    }

    @media only screen and (max-width: 500px) {
        margin-top: 24px;

        > div {
            img {
                width: 18px;
                height: 18px;
            }

            h4 {
                font: 12px/20px 'SF-Pro-Display';
            }
        }
    }
}

.ASO_Package_Drawer_Checkbox {
    display: flex;
    margin: 32px 34px 0 34px;

    p {
        font: 14px/24px 'SF-Pro-Display';
        color: #233862;
        margin-top: -3px;
        cursor: pointer;
    }

    @media only screen and (max-width: 500px) {
        margin: 23px 0 0 28px;

        p {
            font: 12px/20px 'SF-Pro-Display';
            max-width: 240px;
        }
    }
}

.ASO_Package_Drawer_Buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 34px 51px 34px;

    @media only screen and (max-width: 500px) {
        margin: 14px 28px 32px 28px;
    }
}

.ASO_AppliancesText {
    font: 15px/25px 'SF-Pro-Display';
    color: #3d5075;

    span {
        font-family: 'SF-Pro-Display-Bold';
    }
}

.ASOSelectedSolution_DisputeFlag {
    background: #002c68;
    padding: 10px 23px;
    border-radius: 5px;

    p {
        font: 12px/20px 'SF-Pro-Display';
        color: #ffffff;
        margin-bottom: 0;
    }
}

.ASOTopColumn_Admin {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ASOCreateAccount_Admin {
    padding-bottom: 120px;
    width: 100% !important;
}

.ASONavWrapper_Admin_Right {
    display: flex;
    align-items: center;
    gap: 9px;

    > button {
        margin-right: 29px;
    }

    @media only screen and (max-width: 500px) {
        > button {
            margin-right: 12px;
        }
    }
}

.ASONavWrapper_Provider_Right {
    display: flex;
    align-items: center;
    gap: 20px;

    @media only screen and (max-width: 500px) {
        gap: 10px;
    }
}

.ASONavWrapper_Admin_Avatar {
    width: 51px;
    height: 51px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0, 74, 173);

    span {
        font: 16px/24px 'SF-Pro-Display-Semibold';
        color: white;
    }
}

.ASO_RequestUpdate {
    padding: 48px 80px 48px 80px;

    .ant-select {
        display: block;
    }

    button {
        display: block;
        margin: 60px auto 0 auto !important;
    }
}

.AsoNav {
    width: 100%;
    padding: 0 24px;
    display: flex;
    justify-content: center;

    > div {
        width: 100%;
        max-width: 350px;

        .SelectBox div {
            top: 0px;
        }
    }
}

.ASOUpdateUpfrontDeposit {
    h6 {
        color: #3d5075;
        font-family: 'SF-Pro-Display-Bold';
        font-size: 14px;
        font-style: normal;
        line-height: 24px;
    }
    > p {
        color: #3d5075;
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        font-style: normal;
        line-height: 24px;
        margin-bottom: 16px;
    }
}
.primary-button {
    background-color: #004aad;
    padding: 8px 12px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    gap: 6px;
    color: #ffffff;
    font-size: 12px;
    font-family: 'SF-Pro-Display-Semibold';
    cursor: pointer;
}
.ASOCreateAccountStepText_Heading .ASOBankAnalysisStyle {
    border-radius: 3px;
    background: #e2eeff;
    padding: 0px 8px;
    display: flex;
    align-items: center;

    > span {
        color: #004aad;
        font-family: 'SF-Pro-Display-SemiBold';
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 1.02px;
        text-transform: uppercase;
        margin-right: 3px;
    }

    > div svg {
        height: 11px;
    }

    > div span {
        font-family: 'SF-Pro-Display';
    }
}
.ASOUpdateUpfrontDeposit {
    h6 {
        color: #3d5075;
        font-family: 'SF-Pro-Display-Bold';
        font-size: 14px;
        font-style: normal;
        line-height: 24px;
    }
    > p {
        color: #3d5075;
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        font-style: normal;
        line-height: 24px;
        margin-bottom: 16px;
    }
}
.primary-button {
    background-color: #004aad;
    padding: 8px 12px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    gap: 6px;
    color: #ffffff;
    font-size: 12px;
    font-family: 'SF-Pro-Display-Semibold';
    cursor: pointer;
}
.ASOCreateAccountStepText_Heading .ASOBankAnalysisStyle {
    border-radius: 3px;
    background: #e2eeff;
    padding: 0px 8px;
    display: flex;
    align-items: center;

    > span {
        color: #004aad;
        font-family: 'SF-Pro-Display-SemiBold';
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 1.02px;
        text-transform: uppercase;
        margin-right: 3px;
    }

    > div svg {
        height: 11px;
    }

    > div span {
        font-family: 'SF-Pro-Display';
    }
}

.AlignCenter {
    display: flex;
    align-items: center;
    p {
        margin: auto 0;
    }
}

.RepaymentPlansLoaderContainer {
    padding: 20px;
    width: 100%;
    height: 178px;
    background: #ffffff;
    border-radius: 7px;
    border: 1px solid #e2eeff;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    margin-bottom: 20px;
}
.ASOPaymentInfo {
    display: block !important;
    justify-content: space-between;

    > div:first-of-type {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    d .ASOSelectedSolutionContentText > span {
        margin-top: -8px;
    }
    .ASOPaymentInfoColTwo {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 8px;
    }
}

.BtnContainer {
    margin-top: 24px;
    width: 100%;

    button {
        width: 100%;
        justify-content: center;
    }
}

.creditInProgress {
    padding: 56px 65px 110px 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
        width: 120px;
        height: auto;
    }

    h2 {
        color: #011a3c;
        text-align: center;
        font-family: 'SF-Pro-Display';
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
    }

    p {
        max-width: 345px;
        color: #4d4d4d;
        text-align: center;
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        font-style: normal;
        line-height: 20px;
    }
}

.NewPackageModal {
    height: 300px;
    margin-top: 80px;
    max-width: 50%;
    margin: 80px auto 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
        color: #011a3c;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
    }

    p {
        color: #4d4d4d;
        text-align: center;
        font-size: 12px;
        line-height: 18px;
    }
}

.Nav_AutoSaveIndicator {
    display: flex;
    align-items: center;
    background-color: #e2eeff;
    padding: 16px 24px;
    border-radius: 30px;
    gap: 8px;

    > span {
        font: 12px/18px var(--font-regular);
        margin: 0;
        color: #004aad;
    }

    > svg {
        width: 12px;
        height: 12px;
        flex-shrink: 0;
    }

    @media only screen and (max-width: 500px) {
        padding: 13px 15px;
    }
}
