.warningWrapper {
    padding: 60px 52px 38px 52px;

    > h2 {
        font: 20px/100% var(--font-bold);
        max-width: 283px;
        text-align: center;
        margin: 0 auto 11px auto;
        color: #011a3c;
    }

    > p {
        font: 14px/21px var(--font-regular);
        max-width: 368px;
        text-align: center;
        margin: 0 auto 66px auto;
        color: #233862cc;
    }
}

.overrideWrapper {
    padding: 48px 80px;
}

.warningWrapper,
.overrideWrapper {
    @media only screen and (max-width: 500px) {
        padding: 20px;
    }
}

.warningIcon {
    display: flex;
    justify-content: center;
    margin-bottom: 14px;

    img {
        width: 117.29px;
        height: 117.29px;
        text-align: center;
    }
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
}
