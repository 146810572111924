.Container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    padding: 19px 24px;
    border-radius: 6px;
    min-height: 200px;
    background-color: #fff;
    box-shadow: 0px 0px 9px 0px #0000000f;
    color: var(--primary-blue);
    max-width: 490px;
}

.CardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 26px;
    h3 {
        font-family: var(--font-semibold);
        font-size: 15px;
        line-height: 17.9px;
    }
}

.CardBody {
    p {
        font-size: 14px;
        line-height: 21px;
        color: rgba(1, 26, 60, 0.75);
    }
}

.CardFooter {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.CardFooterCol {
    display: flex;
    flex-direction: column;

    h5 {
        font-family: var(--font-medium);
        font-size: 9px;
        line-height: 10.74px;
        letter-spacing: 0.055em;
        opacity: 85%;
        color: #011a3cd9;
    }

    p {
        font-family: var(--font-semibold);
        font-size: 12px;
        line-height: 25px;
    }
}
