.ModalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 374px;
}

.ModalHeader {
    font: 16px/24px 'SF-Pro-Display';
    color: #011a3c;
    margin-bottom: 7px;
    text-align: center;
}

.ModalText {
    font: 12px/20px 'SF-Pro-Display';
    color: #4d4d4d;
    text-align: center;
    max-width: 294px;
}

.CheckboxWrapper {
    display: flex;
    align-items: center;
    gap: 6px;
    border-top: 0.3px solid #B0B0B0;
    padding: 20px 20px 0px;
    margin-top: 20px;

    &>p {
        font: 14px/150% var(--font-regular);
        letter-spacing: 0%;
        color: #233862;
    }
}