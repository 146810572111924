.Wrapper {
    margin: 20px 140px;

    @media screen and (min-width: 1366px) {
        margin: 20px 10%;
    }

    @media screen and (min-width: 600px) and (max-width: 800px) {
        margin: 20px 0px;
        padding: 30px 100px;
    }

    @media screen and (max-width: 500px) {
        margin: 20px 0px;
    }
}

.Heading {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 23px;
    line-height: 27px;
    color: #011a3c;
    margin: 0;

    @media screen and (max-width: 500px) {
        margin-bottom: 20px;
    }
}

.SubHeading {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 14px;
    line-height: 28px;
    text-align: right;
    color: #004aad;
    cursor: pointer;
    margin-bottom: 12px;
}

.DashboardWrapper {
    width: 100%;
    height: 139px;
    background: #011a3c;
    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;

    @media screen and (min-width: 1366px) {
        width: 100%;
    }

    @media screen and (max-width: 1024px) {
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    @media screen and (max-width: 600px) {
        display: none;
    }
}

.MobileDashboardWrapper {
    width: 100%;
    height: 154px;
    background: #011a3c;
    border-radius: 10px;
    display: none;

    @media screen and (max-width: 600px) {
        display: block;
    }
}

.Dashboard {
    font-family: 'SF-Pro-Display';
    text-transform: uppercase;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid rgba(255, 255, 255, 0.29);
    margin: 35px 0px;
    width: 100%;
    padding: 0 40px;

    @media screen and (max-width: 768px) {
        margin-left: 20px;
        padding: 20px 20px;
        width: 100%;
        height: fit-content;
        margin: auto;
    }

    @media screen and (min-width: 1200px) {
        padding: 10px 80px;
    }

    @media screen and (max-width: 600px) {
        border: none;
        width: 100%;
        align-items: center;
        display: flex !important;
        margin: 45px 0px;
    }
}

.DashboardTitle {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.14em;
}

.DashboardDateWrapper {
    display: flex;
}

.DashboardDate {
    width: 83px;
    height: 24px;
    background: #fff4d2;
    border-radius: 3px;
    color: #201265;
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-left: 10px;

    @media screen and (max-width: 600px) {
        height: 20px;
    }
}

.DashboardSubtitle {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0px;
}

.PaymentBreakdownWrapper {
    font-family: 'SF-Pro-Display';
    margin: 30px 0px;

    @media screen and (max-width: 1024px) {
        width: 100%;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        margin-bottom: 20px;
    }

    > div:first-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
}

.PaymentBreakdownTitle {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 15px;
    line-height: 18px;
    color: #011a3c;
}

.StepWrapper {
    display: flex;
    flex-direction: column;
    margin: 30px auto;
}

.DefaultVerticalLine {
    width: 1px;
    height: 75px;
    border-right: 1px solid #f7f7f7;
    margin: 5px 12px;
}

.GreenVerticalLine {
    border-right: 1px solid #61c9a8;
}

.DefaultStepper {
    width: 24px;
    height: 24px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 20px;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #040403;
    padding: 4px;
}

.ActiveStepper {
    background: #011a3c;
    font-family: 'SF-Pro-Display-Bold';
    color: #ffffff;
}

.checkedStepperWrapper {
    background: #61c9a8;
    border: 1px solid #61c9a8;
}

.ContentWrapper {
    display: flex;
}

.TableWrapper {
    margin-left: 30px;
    margin-top: 10px;

    @media screen and (max-width: 500px) {
        margin-left: 10px;
    }
}

.RowWrapper {
    width: 100%;
    height: 70px;
    background: #ffffff;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    display: flex;
    font-family: 'SF-Pro-Display';
    align-items: center;
    margin-bottom: 36px;

    @media screen and (max-width: 768px) {
        width: 100%;
        justify-content: space-evenly;
    }

    @media screen and (max-width: 600px) {
        cursor: pointer;
        width: 100%;
        justify-content: space-between;
    }
}

.RowColumnOne {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    width: 20%;

    @media screen and (max-width: 768px) {
        width: 10%;
        margin-left: 0px;
    }
}

.TableData {
    width: 21.5%;

    @media screen and (max-width: 1024px) {
        width: 20%;
    }
}

.TableBtnWrapper {
    width: 25%;
    display: flex;
    justify-content: center;
}

.TableBtnContainer {
    display: flex;
    gap: 10px;
}

.TableBtn {
    width: 103px;
    height: 37px;
    background: #004aad;
    border-radius: 25px;
    font-family: 'SF-Pro-Display-Medium';
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    border: none;
    cursor: pointer;
}

.TableBtnSecondary,
.TableBtnShortPaid {
    width: 103px;
    height: 37px;
    background: #e2eeff;
    border-radius: 25px;
    font-family: 'SF-Pro-Display-Medium';
    font-size: 12px;
    line-height: 14px;
    color: #004aad;
    border: none;
    cursor: pointer;

    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }

    &:hover {
        background: #004aad;
        color: #ffffff;
    }
}

.TableBtnShortPaid {
    width: 90px;
}

.RowColumnOneFirst {
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: rgba(1, 26, 60, 0.7);
}

.RowColumnOneSecond {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 18px;
    line-height: 21px;
    color: #011a3c;
}

.PaidStatus {
    width: 54px;
    height: 23px;
    background: #edfff5;
    border-radius: 3px;
    font-family: 'SF-Pro-Display-Bold';
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.055em;
    color: #32cc79;
    text-align: center;
    padding: 5px;
}

.BuyoutStatus {
    width: fit-content;
    height: 23px;
    border-radius: 3px;
    background: rgba(229, 229, 229, 0.5);
    font-family: 'SF-Pro-Display-Bold';
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1.02px;
    color: rgba(35, 56, 98, 0.7);
    text-align: center;
    padding: 5px 8px;
}

.DueStatus {
    width: 82px;
    height: 23px;
    background: #fff9e7;
    border-radius: 3px;
    font-family: 'SF-Pro-Display-Bold';
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.055em;
    color: #ffc222;
    text-align: center;
    padding: 5px;
}

.TableDate {
    font-size: 14px;
    line-height: 17px;
    color: #011a3c;
}

.NoActivityWrapper {
    height: 300px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px auto;

    @media screen and (max-width: 1024px) {
        width: 450px;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    }
}

.EmptyHeading {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    color: #011a3c;
    margin-top: 45px;
    margin-bottom: 5px;
}

.EmptyText {
    font-size: 13px;
    line-height: 16px;
    color: rgba(1, 26, 60, 0.8);
}

.SkeletonWrapper {
    margin-bottom: 100px;
}

.TopSkeletonWrapper {
    display: flex;
    justify-content: space-between;
}

.TopLeft {
    width: 209px !important;
    height: 25px !important;
    border-radius: 6px;

    @media screen and (max-width: 600px) {
        width: 100% !important;
    }
}

.TopRight {
    width: 209px !important;
    height: 20px !important;
    border-radius: 2px;
    margin-top: 10px;

    @media screen and (max-width: 600px) {
        width: 100% !important;
        margin-top: 15px;
    }
}

.MiddleSkeletonWrapper {
    width: 968px;
    height: 139px;
    background: #ffffff;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 7px;
    margin-top: 25px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 50px;

    @media screen and (min-width: 1366px) {
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
    }
}

.MiddleRow {
    display: flex;
    flex-direction: column;
    padding: 30px;
}

.MiddleTopColumn {
    width: 133px;
    height: 26px;
    border-radius: 4px;
    margin-bottom: 15px;

    @media screen and (max-width: 600px) {
        width: 100% !important;
    }
}

.MiddleBottomColumn {
    width: 175px;
    height: 33px;
    border-radius: 7px;
}

.SkeletonMiddle {
    width: 187px;
    height: 25px !important;
    border-radius: 6px;
}

.BottomSkeletonWrapper {
    width: 968px;
    height: 70px;
    background: #ffffff;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 7px;
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 40px;

    @media screen and (min-width: 1366px) {
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
    }
}

.BottomRow {
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin-left: 30px;
}

.BottomSmallColumn {
    width: 58px;
    height: 16px !important;
    border-radius: 2px;
    margin-bottom: 5px;
}

.BottomColumn {
    width: 35px;
    height: 23px !important;
    border-radius: 4px;
}

.BottomColumn1 {
    width: 58px;
    height: 16px !important;
    border-radius: 2px;
}

.BottomColumn2 {
    width: 72px;
    height: 16px !important;
    border-radius: 2px;
}

.BottomColumn3 {
    width: 109px;
    height: 16px !important;
    border-radius: 2px;
}

.BottomColumn4 {
    width: 109px;
    height: 37px !important;
    border-radius: 6px;
}

.MobileHide {
    @media screen and (max-width: 600px) {
        display: none;
    }
}

.DesktopHide {
    display: none;

    @media screen and (max-width: 600px) {
        display: block;
    }
}

.ConsumerFloatBox {
    position: absolute;
    right: 0;
    right: 58px;
    bottom: 31px;

    @media screen and (max-width: 600px) {
        display: none;
    }
}

.CarouselWrapper {
    & > ul {
        bottom: -20px !important;

        li.slick-active button:before {
            display: none;
        }
    }
}
