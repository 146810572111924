.Wrapper {
    margin: 0px 50px 90px;

    @media screen and (max-width: 600px) {
        margin: 0px 20px 159px;
    }
}

.PaymentPlansBodyWrapper {
    max-height: 50vh !important;
}
