.ProfileCard {
    padding: 16px !important;
}

.Card {
    border-radius: 16px;
    border: 1px solid #e2eeff;
    background: #fff;
    padding: 16px;

    .DetailsDropdownContainer {
        background-color: white !important;
    }
}

.ProfileName {
    color: #001837;
    font-family: var(--font-bold);
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 26px;
}

.AboutProfile {
    margin-bottom: 16px;
    padding-bottom: 0px !important;
    border-bottom: 0 !important;

    h6 {
        color: #3d5075;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 4px;
    }

    p {
        color: #001837;
        font-family: var(--font-light);
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; /* 18px */
    }
}

.PackageContainer {
    padding: 16px;
    margin-bottom: 17px;

    h5 {
        color: #070c18;
        font-family: var(--font-bold);
        font-size: 14px;
        line-height: 120%;
        margin-bottom: 16px;
    }
}

.search {
    width: 100%;
    height: 42px;
    border: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    border-radius: 38px;
    position: relative;
    background: #fff;

    &:focus-within {
        border-color: #004aad;
    }

    input {
        flex: 1;
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
        background: none;
        text-indent: 3px;
        position: relative;

        &:focus {
            outline: none;
            box-shadow: none;
            border: none;
        }

        &:focus {
            border: none;
            outline: none;
            appearance: none;
        }

        &::placeholder {
            font: 14px/26px var(--font-regular);
            color: #c4c4c4;
            opacity: 0.78;
        }

        @media (max-width: 500px) {
            &::placeholder {
                width: 100px;
            }
        }
    }

    svg {
        margin-left: 16px;
        cursor: pointer;
        width: 13.56px;
        height: 13.56px;
        path {
            stroke: #4d4d4d;
        }
    }

    @media screen and (max-width: 600px) {
        border: 1px solid #e5e5e5;
        padding: 5px 0;
        border-radius: 7px;
        width: 80%;
    }
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    padding-top: 4px;
    margin-bottom: 16px;
    align-items: center;

    svg {
        stroke: black;
        height: 39px;

        &:hover {
            cursor: pointer;
        }
    }
}

.header {
    color: rgba(0, 0, 0, 0.8);
    font-family: var(--font-bold);
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0;
}

.toggleStyle {
    & > div:first-of-type {
        padding: 16px !important;

        > p {
            color: #070c18;
            font-family: var(--font-bold);
            font-size: 14px;
            line-height: 120%;
        }
    }

    .isOpen {
        padding: 16px 10px;
    }
}

.EmptyState {
    margin-top: 32px;

    & > div {
        border: none !important;
    }
}

.LoadingState {
    max-width: 348px;
    margin: 170px auto 0 auto;
    color: #4d4d4d;
    text-align: center;

    svg {
        height: 78.3px;
        width: 78.3px;
        margin: 0 auto;
    }

    h6 {
        font-size: 18px;
        line-height: 150%;
    }

    p {
        font-size: 14px;
        line-height: 150%;
    }
}
