.PartnerSectionTwoWrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.PartnerSectionTwoChildOneWrapper {
  margin-top: 50px;
  width: 100%;

  @media screen and (max-width: 1024px) {
    .slick-dots {
      bottom: -70px
    }
  }

  @media screen and (max-width: 500px) {
    margin: 50px 0;
    padding: 0 21px;

    .slick-slider {
      height: 11vh;
    }
  }

  >p {
    text-align: center;
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #233862;
    margin-bottom: 31px;
  }
}

.PSTCOLogoWrapper {
  display: flex !important;
  grid-column-gap: 113px;
  justify-content: center;
}

.PSTCSectionOneWrapper {
  padding: 0 0 0 calc(110px + 1%);

  @media (max-width: 767px) {
    padding: 0 10px;
  }

  @media (min-width: 767px) and (max-width: 1024px) {
    margin-bottom: 90px;
    padding: 0;
    display: flex;
    justify-content: center;
  }

  @media screen and (min-width: 992px) and (max-width: 998px) {
    padding: 0 10px 0 10px;
  }

  @media screen and (min-width: 998px) and (max-width: 1190px) {
    padding: 0 10px 0 30px;
  }

  @media screen and (max-width: 1921px) and (min-width: 1580px) {
    padding: 0 0 0 calc(100px + 25%);
  }

  @media screen and (min-width: 1922px) {
    padding: 0 0 0 calc(200px + 45%);
  }

  @media screen and (min-width: 2700px) {
    font-size: calc(30px + 5px);
    padding: 0 0 0 calc(200px + 45%);
    line-height: calc(106px + 20px);
  }
}

.PartnerSectionTwoChildTwoWrapper {
  width: 100%;
  margin: 110px 0 200px 0;

  @media screen and (max-width: 500px) {
    margin: 20px 0 0 0;
  }

  .ant-collapse>.ant-collapse-item {
    border-bottom: 1px solid #eeeeee;
  }
}

.PSTCSectionOneH4 {
  font-family: 'SF-Pro-Display-Semibold';
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #004AAD;
}

.PSTCSectionOneH2 {
  font-family: 'SF-Pro-Display-Bold';
  font-style: normal;
  font-size: 35px;
  line-height: 45px;
  color: #011A3C;
  width: 416px;
  margin-bottom: 40px;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.AccordionBodyText {
  font-family: 'SF-Pro-Display';
  font-size: 16px;
  line-height: 24px;
  color: rgba(1, 26, 60, 0.75);
}

.AccordionButton {
  font-family: 'SF-Pro-Display-Semibold';
  border: none;
  background: #fff;
  font-size: 14px;
  line-height: 17px;
  color: #004AAD;
  margin: 19px 0 0 0;
  padding: 0;
  cursor: pointer;
}

.PartnerSectionThreeWrapper {
  width: 100%;
  background: url(../../assets/images/bg-blue.png);
  background-repeat: no-repeat;
  background-size: 100% 50%;
  height: 100%;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  >img {
    width: auto;

    @media screen and (max-width: 1024px) {
      width: 90%;
    }
  }
}

.PartnerSectionFourWrapper {
  width: 100%;
  background: url(../../assets/images/bg-blue.png);
  background-attachment: fixed;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 96px 0 89px 0;

  >div:first-child {
    display: flex;
    margin-bottom: 68px;
    grid-column-gap: 150px;

    @media screen and (max-width: 1023px) {
      display: grid;
      grid-row-gap: 80px;
    }
  }
}

.PartnerSectionFourIconTitle {
  font-family: 'SF-Pro-Display-Bold';
  font-size: 22px;
  line-height: 26px;
  color: #FFFFFF;
}

.PartnerSectionFourIconSubTitle {
  font-size: 13px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.63);
  margin-top: 10px;
}