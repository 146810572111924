.nameContainer {
    display: flex;
    align-items: center;
    gap: 10px;

    h4 {
        color: rgba(35, 56, 98, 0.88);
        font-family: var(--font-semibold);
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 0;
    }
}

.arrow {
    margin-top: -1px;
    width: 14px;
    height: 18px;

    &:hover {
        cursor: pointer;
    }
}

.nameAndLogo {
    display: flex;
    align-items: center;
    gap: 8px;
}

span.nameAndLogo {
    margin-bottom: 6px;
    padding: 6px;

    p {
        color: #233862;
        font-size: 14px;
        line-height: normal;
    }
}

.logo {
    width: 25.4px;
    height: 25.4px;
    border-radius: 100%;
}

.search {
    margin: 18px 17px;
    height: 42px;
    border: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    border-radius: 38px;
    position: relative;
    background: #fff;
    padding: 0 16px;

    &:focus-within {
        border-color: #004aad;
    }

    input {
        flex: 1;
        height: 100%;
        width: max-content;
        border: none;
        outline: none;
        background: none;
        text-indent: 8px;
        position: relative;

        &:focus {
            outline: none;
            box-shadow: none;
            border: none;
        }

        &:focus {
            border: none;
            outline: none;
            appearance: none;
        }

        &::placeholder {
            font: 14px/26px var(--font-regular);
            color: #c4c4c4;
            opacity: 0.78;
        }

        @media (max-width: 500px) {
            &::placeholder {
                width: 100px;
            }
        }
    }

    svg {
        width: 13.559px;
        height: 13.559px;

        path {
            stroke: #4d4d4d;
        }
    }
}

.menu {
    min-width: 240px;
    & > ul {
        min-width: 240px !important;
        width: 100%;

        li {
            background-color: white !important;

            span {
                width: 100%;
            }
        }
    }
    @media screen and (max-width: 500px) {
        min-width: 300px !important;
        margin-left: 20px;
    }
}

.clusterList {
    max-height: 205px;
    overflow: scroll;
}

.noClusterMsg {
    text-align: center;
}

.loader {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0px 16px;
    margin-bottom: 16px;
}
