.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:has(.leftContent) {
        align-items: flex-end;
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }
}

.btns,
.multipleMemberBtns {
    display: flex;
    align-items: center;
    gap: 16px;
}

.multipleMemberBtns {
    gap: 10px;
}

.greeting {
    font: 18px/27px var(--font-regular);
    color: #233862e0;
    margin: 0;
}

.leftContent {
    width: 100%;
    > h2 {
        font: 16px/24px var(--font-regular);
        color: #011a3c;
        margin-bottom: 2px;
    }

    > p {
        font: 14px/21px var(--font-light);
        color: #4d4d4d;
        margin-bottom: 0;
    }
}

.navAndBtn {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 600px) {
        margin-bottom: 16px;
    }

    button {
        align-self: flex-end;
        @media screen and (min-width: 600px) {
            display: none;
        }
    }
}

.desktopBtn {
    @media screen and (max-width: 600px) {
        display: none;
    }
}

.moneysBlueIcon {
    margin-right: 6px;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}

.otherActions {
    border: 1px solid #004aad;
    display: flex;
    align-items: center;
    color: #004aad;
    padding: 10px 20px;
    border-radius: 30px;
    font-family: var(--font-semibold);
    cursor: pointer;
    gap: 8px;

    svg {
        margin-top: 2px;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        max-width: 200px;
        padding: 10px 8px;
    }
}
