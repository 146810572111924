.ViewSystemContainer {
    padding: 47px 150px;
    @media only screen and (max-width: 1365px) {
        padding: 82px 100px;
    }
    @media only screen and (max-width: 1200px) {
        padding: 82px 60px;
    }
    @media only screen and (max-width: 992px) {
        padding: 82px 60px;
    }
    @media only screen and (max-width: 480px) {
        padding: 42px 20px;
    }

    @media only screen and (min-width: 1441px) {
        padding: 82px 150px;
    }

    @media only screen and (min-width: 1600px) {
        margin: auto;
        padding: 82px;
        width: fit-content;
    }
}

.VS_RightSection {
    width: 100%;
    @media only screen and (min-width: 1200px) {
        max-width: 400px;
    }
}

.ViewSystemTopButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    @media only screen and (max-width: 800px) {
        margin-bottom: 10px;
    }
}

.ViewSystemTopButtons_Updated {
    @media only screen and (max-width: 800px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
    }
}

.VS_TopRow_Right_ShareBtn {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    padding: 5px;
    border-radius: 30px;

    img {
        width: 16px;
        height: 16px;
        object-fit: cover;
    }

    span {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 14px;
        line-height: 17px;
        color: #004aad;
    }

    &:hover {
        background: #f9fcff;
    }
}

.ViewSystemTop {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    gap: 16px;

    span {
        grid-column: 1 / 3;
    }
    img {
        grid-column: 1 / 3;
        width: 100%;
        height: 502px;
        border-radius: 12px;
        object-fit: cover;
        @media screen and (max-width: 390px) {
            grid-column: 1 / 2;
            width: 100%;
        }
    }
    @media only screen and (max-width: 1200px) {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 40px;
        img {
            width: 100%;
            height: auto;
        }
    }

    &.ViewSystemTop_Updated {
        @media only screen and (max-width: 500px) {
            gap: 0;
        }
    }
}

.ViewSystemTopText {
    width: 100%;
    grid-column: 3 / 4;
    margin-top: 42px;

    h2 {
        font-family: 'SF-Pro-Display';
        font-size: 16px;
        line-height: 24px;
        color: #070c18;
        margin-bottom: 8px;
    }

    h1 {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 28px;
        line-height: 35px;
        color: #000f23;
        margin-bottom: 24px;
    }

    @media only screen and (max-width: 1200px) {
        margin-top: 0;
        grid-column: 1 / 2;

        h2 {
            font-size: 14px;
            line-height: 23px;
            margin-bottom: 4px;
        }

        h1 {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 8px;
        }
    }
}

.ViewSystemTopTextFlags {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 20px;

    div {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        background: #fafafa;
        border-radius: 3px;
        width: fit-content;
        padding: 1.5px 8px;

        p {
            font-family: 'SF-Pro-Display-Semibold';
            font-size: 12px;
            line-height: 28px;
            color: #4d4d4d;
        }
    }
}

.ViewSystemTopTextCard {
    background: #f9fcff;
    border-radius: 12px;
    width: 100%;
    padding: 24px 17px;

    @media only screen and (max-width: 500px) {
        width: 100%;
        padding-bottom: 18px;
    }
}

.ViewSystemTopTextCardText {
    span {
        color: #001837;
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        margin-bottom: 8px;
    }

    p {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 14px;
        line-height: 24px;
        color: #070c18;
    }

    &.Left {
        width: 120px;
    }

    &.Right {
        text-align: left;
    }

    &.Main {
        margin-bottom: 24px;
        span {
            font-size: 14px;
        }

        p {
            font-size: 22px;
            line-height: 35px;
        }
    }

    @media only screen and (max-width: 500px) {
        &.Main {
            p {
                font-size: 16px;
                line-height: 19.09px;
            }
        }

        &.Left {
            width: fit-content;
        }

        &.Right {
            text-align: right;
        }
    }
}

.ViewSystemTopTextCardText_LoadInfo {
    display: flex;
    align-items: center;
    gap: 5px;

    img {
        width: 14px;
        height: 14px;
        flex-shrink: 0;
        margin-top: -3px;
    }
}

.ViewSystemTopTextCardTextWrapper {
    display: flex;
    align-items: center;
    gap: 72px;
    margin-bottom: 21px;

    @media only screen and (max-width: 500px) {
        margin-bottom: 16px;
        gap: 0px;
        justify-content: space-between;
    }
}

.ViewSystemTopTextCardInfo {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    margin-bottom: 14px;
    padding: 12px 24px 8px 24px;
    max-width: 336px;
    height: fit-content;
    background: #e2eeff;
    border-radius: 5px;

    img {
        width: 11px;
        height: 11px;
        margin-top: 2px;
    }

    p {
        font-family: 'SF-Pro-Display';
        font-size: 10px;
        line-height: 14px;
        color: #000000;
        margin: 0;
    }

    @media only screen and (max-width: 500px) {
        width: 100%;
    }
}

.ViewSystemTopTextButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .ViewSystemTextButton {
        cursor: pointer;
        span {
            padding: 16px 14px;
            text-align: center;
            font-family: 'SF-Pro-Display-Semibold';
            font-size: 13px;
            font-style: normal;
            line-height: normal;
            color: #004aad;
            @media screen and (max-width: 1366px) {
                padding: 8px 7px;
            }
        }
    }

    @media only screen and (max-width: 500px) {
        flex-direction: column-reverse;
        gap: 16px;
    }
}

.ViewSystemContactCTA {
    &.PageTop {
        margin: 10px 0 0 17px;
    }

    @media only screen and (max-width: 500px) {
        &.PageTop {
            margin-left: 0;
        }

        &.PaymentSection {
            flex-direction: column;
            align-items: center;
            margin-top: 21px;
        }
    }
}

.ViewSystemBottomRight {
    grid-column: 3/4;
    max-width: 100%;
    @media screen and (max-width: 1200px) {
        grid-column: 1/2;
        width: 100%;
    }
}

.ViewSystemBottom {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    gap: 16px;

    @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(1, 1fr);
    }
    @media only screen and (max-width: 500px) {
        width: 100%;
        row-gap: 30px;
        column-gap: 0px;
    }
}

.ViewSystemTabsWrapper {
    // margin: 66px 0 0 0;
    grid-column: 1/3;

    @media only screen and (max-width: 500px) {
        margin-top: 47px;
        width: 100%;
    }
}
.ViewSystemTabTitle {
    display: flex;
    max-width: 100%;
    justify-content: center;
    overflow: auto;
    scrollbar-width: none;
    border-bottom: 1px solid #e2eeff;
    gap: 76px;

    @media screen and (max-width: 500px) {
        justify-content: flex-start;
        gap: 31px;
    }
    &::-webkit-scrollbar {
        display: none;
    }
    p {
        word-break: keep-all;
        min-width: fit-content;
        padding-bottom: 10px;
        font-size: 14px;
        line-height: 24px;
        color: #004aad;
        font-family: 'SF-Pro-Display';
        cursor: pointer;
    }
}

.ViewSystemTabsContentOverview {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 28px;
    row-gap: 52px;
    margin-top: 24px;
    max-width: 757px;
    padding-bottom: 46px;
    border-bottom: 0.5px solid #e2eeff;
    justify-content: flex-start;

    @media only screen and (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
        padding-bottom: 0;
        border: none;
        column-gap: 40px;
        row-gap: 35px;
        div:nth-child(3) {
            margin-left: -7px;
        }
    }
    @media only screen and (max-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
        padding-bottom: 0;
        border: none;
        column-gap: 40px;
        row-gap: 35px;
        div:nth-child(3) {
            margin-left: -7px;
        }
    }
    @media only screen and (max-width: 365px) {
        column-gap: 20px;
    }
}

.ViewSystemTabsOverviewItem {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    width: 170px;
    @media screen and (max-width: 500px) {
        width: 100%;
    }
    svg {
        margin-top: 5px;
        flex-shrink: 0;
    }
    div {
        span {
            font-size: 12px;
            line-height: 20px;
            color: #4d4d4d;
        }

        p {
            font-size: 14px;
            line-height: 24px;
            color: #070c18;
        }
    }
}

.ViewSystemTabsContentPayment {
    width: 100%;
    background: #f9fcff;
    border-radius: 8px;
    margin-top: 24px;
    padding: 34px 24px 28px 24px;

    @media only screen and (max-width: 800px) {
        padding: 25px 30px 28px 30px;
    }
}

.ViewSystemTabsPaymentTop {
    display: flex;
    align-items: flex-start;
    gap: 39px;

    @media only screen and (max-width: 1200px) {
        width: 100%;
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.ViewSystemTabsPaymentTop_Right {
    width: 100%;
}

.ViewSystemTabsPaymentTop__Outright_Sale_Extra_Content {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 24px;
}

.ViewSystemTabsPayment_Insurance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    background-color: #e2eeff;
    padding: 8px 15px;
    border-radius: 4px;
    margin-bottom: 15px;

    p {
        font: 12px/20px 'SF-Pro-Display-Medium';
        color: #3d5075;
    }

    .ViewSystemTabsPayment_Insurance_Num {
        display: flex;
        align-items: center;
        gap: 4px;
        span {
            font: 14px/24px 'SF-Pro-Display-Bold';
            color: #004aad;
        }

        img {
            width: 14px;
            height: 14px;
            flex-shrink: 0;
        }
    }
}

.ViewSystemTabsPaymentText {
    h4 {
        font-family: 'SF-Pro-Display';
        font-size: 16px;
        line-height: 24px;
        color: #070c18;
        margin-bottom: 8px;
    }

    h3 {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 18px;
        line-height: 24px;
        color: #000f23;
        margin-bottom: 10px;
    }

    p {
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 20px;
        color: #070c18;
        max-width: 240px;
        @media screen and (max-width: 768px) {
            max-width: 100%;
        }
    }
}

.ViewSystemTabsPaymentTopExtraContent {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 10px;

    p {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 18px;
    }
}

.ViewSystemNav {
    background: #004aad;
    padding: 20px 120px;
    position: sticky;
    top: 0;
    z-index: 100;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    @media only screen and (max-width: 1200px) {
        padding: 16px 60px;
    }
    @media only screen and (max-width: 1024px) {
        grid-template-columns: repeat(1, 1fr);
        padding: 16px 40px;
        align-items: flex-start;
    }
    @media only screen and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
        padding: 16px 20px;
        align-items: flex-start;
    }

    @media only screen and (min-width: 1440px) {
        padding: 20px 150px;
    }
}

.ViewSystemNavLeft {
    display: flex;
    align-items: center;
    gap: 16px;

    img {
        width: 177px;
        height: 130px;
        object-fit: cover;
        border-radius: 3px;
    }

    @media only screen and (max-width: 800px) {
        gap: 20px;
        align-items: flex-start;

        img {
            width: 132px;
            height: 96.95px;
        }
    }
}

.ViewSystemNavRight {
    display: flex;
    align-items: center;
    gap: 34px;
    width: 100%;

    > div {
        display: flex;
        align-items: center;
        gap: 34px;
        width: 100%;

        & :last-child {
            margin-left: auto;
        }
    }

    button {
        margin-top: -14px;
        margin-left: auto;
    }

    @media only screen and (max-width: 800px) {
        width: 100%;
        flex-direction: column;
        margin-top: 12px;
        gap: 0;

        > div {
            width: 100%;
            gap: 0;
            justify-content: space-between;
        }

        button {
            margin-top: 22px;
            margin-left: auto;
        }
    }
}
.RepaymentModalFooterClassName {
    margin-top: -20px;
    @media screen and (max-width: 500px) {
        margin-top: 0px;
    }
}

.ViewSystemNavTextSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    h4 {
        font-family: 'SF-Pro-Display';
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
        margin: 0;
    }

    h1,
    h2 {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 22px;
        line-height: 35px;
        color: #ffffff;
        margin: 0;
        @media screen and (max-width: 1366px) {
            font-size: 18px;
        }
        @media screen and (max-width: 1120px) and (min-width: 1024px) {
            font-size: 16px;
        }
    }

    span {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;
        margin: 0;
    }

    @media only screen and (max-width: 800px) {
        h4 {
            font-size: 16px;
            line-height: 20px;
        }

        h1,
        h2 {
            font-size: 16px;
            line-height: 20px;
        }

        span {
            font-size: 12px;
            line-height: 20px;
        }
    }
}

.ViewSystemL20PlanCard {
    background: #ffffff;
    border-radius: 4px;
    padding: 18px 18px 28px 18px;
    width: 100%;
    margin-bottom: 15px;

    div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
            font-family: 'SF-Pro-Display';
            font-size: 14px;
            line-height: 24px;
            color: #3d5075;
            margin: 0;

            span {
                font-family: 'SF-Pro-Display-Bold';
                color: #000f23;
            }
        }

        h5 {
            font-family: 'SF-Pro-Display';
            font-size: 14px;
            line-height: 24px;
            color: #3d5075;
            margin: 0;
            text-align: right;

            span {
                font-size: 18px;
                font-family: 'SF-Pro-Display-Bold';
                color: #000f23;
            }
        }

        span {
            font-family: 'SF-Pro-Display';
            font-size: 14px;
            line-height: 24px;
            color: #3d5075;
        }

        p {
            font-family: 'SF-Pro-Display-Semibold';
            font-size: 14px;
            line-height: 17px;
            text-align: right;
            color: #000f23;
            margin: auto 0;
        }
    }

    &.ModalVersion {
        border: 1px solid #e2eeff;
        border-radius: 4px;
        margin-bottom: 20px;
    }

    @media only screen and (max-width: 800px) {
        width: 100%;

        div:first-child {
            align-items: flex-start;
        }

        div {
            h4 {
                font-size: 12px;
                line-height: 20px;

                span {
                    font-size: 14px;
                }
            }

            h5 {
                font-size: 14px;
                line-height: 20px;

                span {
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            span {
                font-size: 12px;
            }
        }
    }
}

.ViewSystemL20PlanCards {
    height: 500px;
    overflow-y: auto;
    margin: 45px 94px 45px 94px;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;

    &::-webkit-scrollbar {
        display: none !important;
    }
    @media screen and (max-width: 1280px) {
        height: 450px;
    }

    @media only screen and (max-width: 500px) {
        margin: 16px 51px 60px 51px;
    }
}

.ViewSystemL20PlanCardRowOne {
    margin-bottom: 12px;
}

.ViewSystemL20PlanCardRowTwo {
    margin-bottom: 13px;
}

.ViewSystemL20PlanCard_InsuranceRow {
    padding-bottom: 20px;
    border-bottom: 0.5px solid #dadada;
}

.ViewSystemL20PlanCard_TotalRow {
    margin-top: 13px;
}

.ViewSystemSubscriptionPlanCard {
    background: #ffffff;
    padding: 32px 20px;
    border-radius: 4px;
    width: 461px;

    div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
            font-family: 'SF-Pro-Display';
            font-size: 14px;
            line-height: 24px;
            color: #3d5075;
            margin-bottom: 11px;

            span {
                font-family: 'SF-Pro-Display-Bold';
                color: #000f23;
            }
        }

        h5 {
            font-family: 'SF-Pro-Display';
            font-size: 14px;
            line-height: 24px;
            color: #3d5075;
            margin: 0;
            text-align: right;

            span {
                font-size: 18px;
                font-family: 'SF-Pro-Display-Bold';
                color: #000f23;
            }
        }

        span {
            font-family: 'SF-Pro-Display';
            font-size: 14px;
            line-height: 24px;
            color: #3d5075;
        }

        p {
            font-family: 'SF-Pro-Display-Semibold';
            font-size: 14px;
            line-height: 17px;
            text-align: right;
            color: #000f23;
        }
    }

    @media only screen and (max-width: 800px) {
        width: 100%;

        div:first-child {
            align-items: flex-start;

            h4 {
                font-size: 12px;
                line-height: 20px;

                span {
                    font-size: 14px;
                }
            }
            h5 {
                span {
                    font-size: 14px;
                }
            }

            span {
                font-size: 12px;
            }

            p {
                font-size: 12px;
                line-height: 20px;
            }
        }
    }
}

.ViewSystemTabsPaymentBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    @media only screen and (max-width: 800px) {
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
    }
}

.ViewSystemPaymentBottomBtnIconWrapper {
    .ViewSystemPaymentBottomBtnIconWhite {
        display: none;
    }

    &:hover {
        .ViewSystemPaymentBottomBtnIconWhite {
            display: block;
        }
        .ViewSystemPaymentBottomBtnIconBlue {
            display: none;
        }
    }
}

.ViewSystemPaymentBottomBtn {
    display: flex;
    align-items: center;
    gap: 6px;

    img {
        width: 14px;
        height: 14px;
    }

    @media only screen and (max-width: 500px) {
        gap: 4px;
        justify-content: center;
        img {
            margin-left: -2px;
        }

        span {
            font-size: 12px;
        }
    }
}

.ViewSystemTabsContentSavings {
    background: #f9fcff;
    border-radius: 4px;
    padding: 25px 32px;

    h3 {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 22px;
        line-height: 35px;
        color: #333333;
        margin-bottom: 8px;
    }

    p {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 24px;
        color: #5f5b5b;
        margin-bottom: 24px;
    }
}

.ViewSystemPackageDescriptionText {
    background: #f9fcff;
    border-radius: 4px;
    padding: 22px 18px;
    margin: 42px 0;
    width: 370px;
    .ViewSystemPackageDescriptionParagraphs {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    h4 {
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 12px;
        line-height: 20px;
        color: #001837;
        margin-bottom: 6px;
    }

    p {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 24px;
        color: #070c18;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
        margin-top: 70px;
    }
}

.ViewSystemPackageDescriptionMaker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;

    h4 {
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.065em;
        color: #b0b0b0;
    }

    img {
        width: 150px;
        height: 43px;
        object-fit: cover;
    }
}

.ViewSystemStepsWrapper {
    border: 1px solid #e2eeff;
    border-radius: 4px;
    padding: 36px 18px 31px 23px;

    h4 {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 18px;
        line-height: 24px;
        color: #070c18;
    }
}

.ViewSystemStepsWrapper.collapsible .ant-steps {
    transition: max-height 0.3s ease, opacity 0.3s ease;
    max-height: 100px;
    overflow: hidden;
}

.ViewSystemStepsWrapper.collapsible.open .ant-steps {
    max-height: 1000px;
    opacity: 1;
}

.ViewSystemSteps {
    .ant-steps-item-container {
        min-height: 80px !important;
    }
    .ant-steps-item-tail {
        background: #004aad !important;
        left: 18px !important;
        top: 54px !important;
        width: 0.3px !important;
    }

    .ant-steps-item-title {
        font-family: 'SF-Pro-Display-Bold' !important;
        font-size: 14px !important;
        line-height: 24px !important;
        color: #4d4d4d !important;
        margin-bottom: 4px !important;
        margin-top: 0 !important;
        width: 288px !important;
    }

    .ant-steps-item-description {
        font-family: 'SF-Pro-Display' !important;
        font-size: 14px !important;
        line-height: 24px !important;
        color: #070c18 !important;
    }

    .ant-steps-item-icon {
        width: fit-content !important;
        height: fit-content !important;
        background: transparent !important;
        box-shadow: none !important;

        .ant-steps-icon {
            background: transparent !important;
        }
    }

    @media only screen and (max-width: 500px) {
        .ant-steps-item-title {
            width: 100% !important;
        }

        .ant-steps-item-description {
            font-size: 12px !important;
        }
    }
}

.ViewGetsystemSteps {
    .ant-steps-item-description {
        width: 426px;
        @media screen and (max-width: 576px) {
            width: 90%;
        }
    }
}

.ViewGetSystemSteps_CollapseBtn {
    font: 12px/14.32px var(--font-semibold);
    color: #004aad;
    margin: 0;
    cursor: pointer;
}

.ViewSystemStepsWrapper_v2 {
    border-radius: 8px !important;
    padding: 16px !important;
    width: 100%;

    > h4 {
        font: 12px/24px var(--font-medium) !important;
        color: #070c18 !important;
        text-transform: uppercase !important;
        letter-spacing: 1px !important;
        margin-bottom: 0px !important;
    }

    .ViewSystemSteps {
        .ant-steps-item-title {
            font: 12px/18px var(--font-medium) !important;
            color: #070c18 !important;
        }

        .ant-steps-item-description {
            font: 12px/18px var(--font-light) !important;
            color: #4d4d4d !important;
        }
    }
}

.ViewSystemChart {
    border-bottom: 0.3px solid #b0b0b0;
    display: flex;
    align-items: flex-end;
    gap: 16px;
    justify-content: center;
    width: 100%;
    height: 500px;
    position: relative;
    margin-left: 10px;

    @media only screen and (max-width: 800px) {
        height: 370px;
        width: 100%;
        margin-left: 10px;
        margin-top: 120px;
        gap: 54px;
    }
}

.ViewSystemChartItemText {
    h4 {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 18px;
        line-height: 24px;
        color: #333333;
        text-align: center;
        margin-bottom: -3px;
    }

    span {
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        text-align: center;
        color: #5f5b5b;
        margin: 0 0 8px 20px;
        display: inline-block;
    }

    @media only screen and (max-width: 800px) {
        h4 {
            font-size: 12px;
        }

        span {
            margin: 0 auto;
            display: block;
            text-align: center;
        }
    }
}

.ViewSystemChartBar {
    width: 177px;
    border-radius: 6px 6px 0 0;
    z-index: -1;

    @media only screen and (max-width: 800px) {
        width: 68px;
    }
}

.ViewSystemChartItemLine {
    border-bottom: 1px dashed #c4c4c4;
    width: 620px;
    z-index: 10;
    position: absolute;

    &.first-line {
        margin-left: -50px;
    }

    &.second-line {
        margin-left: -300px;
    }

    @media only screen and (max-width: 800px) {
        width: 280px;

        &.first-line {
            margin-left: -30px;
        }

        &.second-line {
            margin-left: -150px;
        }
    }
}

.ViewSystemChartDifference {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: fit-content;
    margin-left: 16px;

    @media only screen and (max-width: 800px) {
        margin-left: -64px;
    }
}

.ViewSystemChartDifferenceLine {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    div {
        height: 100%;
        border-right: 1px solid #b0b0b0;
        margin: 0;
    }
}

.ViewSystemChartDifferenceMain {
    display: flex;
    align-items: center;
    gap: 16px;

    @media only screen and (max-width: 800px) {
        gap: 0;
    }
}

.ViewSystemChartDifferenceContent {
    h4 {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 18px;
        line-height: 24px;
        color: #333333;
        margin: 0;
    }

    p {
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 20px;
        color: #5f5b5b;
        margin: 0;
    }

    @media only screen and (max-width: 800px) {
        h4 {
            font-size: 12px;
        }
    }
}

.ViewSystemTabsDivider {
    border-bottom: 0.5px solid #e2eeff;
    margin: 24px 0;

    @media only screen and (max-width: 800px) {
        border: none;
        margin: 0 0 30px 0;
    }
}

.PromoComboWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 35px;
    width: 95%;

    p {
        color: rgba(0, 0, 0, 0.8);
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 26.347px;
        letter-spacing: 1.44px;
        text-transform: uppercase;
    }

    a {
        color: #004aad;
        text-align: center;
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 0px;
        text-decoration-line: underline;
    }
}

.ViewSystem_Skeleton {
    display: flex;
    align-items: flex-start;
    gap: 16px;

    > .ant-skeleton-element {
        width: 100%;
        .ant-skeleton-image {
            width: 756px !important;
            border-radius: 12px;
        }
    }

    .ViewSystem_Skeleton_Title {
        margin-top: 20px;
        .ant-skeleton-title {
            margin: 0;
            height: 16px;
        }

        .ant-skeleton-paragraph {
            margin: 6px 0 0 0 !important;
            height: 24px;
        }
    }

    .ViewSystem_Skeleton_Cards {
        display: flex;
        gap: 8px;

        .ant-skeleton.ant-skeleton-element.ant-skeleton-active {
            width: 80px;

            span {
                min-width: 100%;
                width: 100%;
            }
        }
    }

    .ViewSystem_Skeleton_Inner {
        background: #f9fcff;
        padding: 24px 18px;
        border-radius: 12px;
        margin-top: 20px;
        width: 100%;
    }

    .ViewSystem_Skeleton_DoubleColumn {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        margin: 32px 0;
    }

    @media only screen and (max-width: 1200px) {
        flex-direction: column;
        gap: 40px;
        > .ant-skeleton-element {
            .ant-skeleton-image {
                width: 100% !important;
            }
        }
    }

    @media only screen and (min-width: 1600px) {
        > .ant-skeleton-element {
            width: fit-content;
        }

        > div:last-child {
            width: 370px;
        }
    }
}

.ViewSystemNav_SkeletonRow {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 12px;

    .ant-skeleton.ant-skeleton-element {
        width: 100%;

        .ant-skeleton-image {
            width: 132px;
            height: 96px;
            border-radius: 3px;
        }
    }
}

.ViewSystemNav_SkeletonRow,
.ViewSystemNav_SkeletonRight_Rows {
    .ant-skeleton-title {
        margin: 0;
        height: 20px;
    }

    .ant-skeleton-paragraph {
        margin: 6px 0 0 0 !important;
    }

    @media only screen and (min-width: 1025px) {
        .ant-skeleton-title {
            margin-top: 24px;
        }
    }
}

.ViewSystemNav_SkeletonRight,
.ViewSystem_Skeleton_DoubleColumn {
    > .ant-skeleton.ant-skeleton-element {
        text-align: right;
        display: block;
        margin-top: 8px;

        .ant-skeleton-button {
            width: 160px;
            height: 53px;
            border-radius: 30px;
        }
    }
}

.ViewSystemNav_SkeletonRight {
    margin-top: 12px;
    .ViewSystemNav_SkeletonRight_Rows {
        display: flex;
        align-items: flex-start;
    }

    > .ant-skeleton.ant-skeleton-element {
        margin-top: 20px;
    }
    @media only screen and (min-width: 1025px) {
        margin-top: 0;
        display: flex;
        justify-content: space-between;

        .ViewSystemNav_SkeletonRight_Rows {
            width: 100%;
        }

        > .ant-skeleton.ant-skeleton-element {
            margin-top: 20px;
        }
    }
}

.ViewSystem_Skeleton_DoubleColumn {
    @media only screen and (max-width: 500px) {
        > .ant-skeleton.ant-skeleton-element {
            margin-top: 0;

            .ant-skeleton-button {
                width: 144px;
                height: 53px;
                border-radius: 30px;
            }
        }
    }
}

.EditAppliancesModalFooter {
    border-top: none;
}

.EditPrompt {
    max-width: 535px;
    margin: 1rem auto;
    font-size: 14px;

    & > p:first-of-type {
        color: #011a3c;
    }

    & > p:last-of-type {
        color: #4d4d4d;
    }

    @media screen and (max-width: 425px) {
        max-width: 320px;
        margin: 8px auto;
    }
}

.GetStarted {
    h6 {
        text-align: center;
        text-transform: uppercase;
        margin: 1rem 0 0;
        color: #3d5075;
        font-family: 'SF-Pro-Display';
        overflow: hidden;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 1.5px;
    }

    h6::before,
    h6::after {
        background-color: #b0b0b0;
        content: '';
        display: inline-block;
        height: 0.5px;
        position: relative;
        vertical-align: middle;
        width: 14%;
    }

    h6::before {
        right: 1em;
    }

    h6::after {
        left: 1em;
    }

    .BuildSystem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f9fcff;
        border-radius: 8px;
        padding: 12px;
        margin: 12px 0 auto;
        max-width: 370px;
    }
}

.SecondaryModalV2ContentWrapper {
    position: static;

    @media screen and (max-width: 768px) {
        position: relative;
    }
}

.AppliancePreviewFooter {
    border-top: 0px;
    padding: 14px 0px 21px 0px;
    max-width: 435px;
    margin: 0 auto;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 500px) {
        padding: 14px 24px 21px 24px;
        gap: 20px;
    }
}

.VSShareWrapper {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--Accent-Blue-Primary, #004aad);
    text-align: center;
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    cursor: pointer;
    text-wrap: nowrap;
}

.ParentWrapper_Shared {
    > div:not(:first-child) {
        filter: blur(4px);
    }

    > footer {
        filter: blur(4px);
    }
}

.ViewSystemOtherOptionsModal {
    height: 500px;
    overflow-y: auto;
    margin: 45px 94px 45px 94px;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &::-webkit-scrollbar {
        display: none !important;
    }
    @media screen and (max-width: 1280px) {
        height: 450px;
    }

    @media only screen and (max-width: 500px) {
        margin: 40px 20px;
        height: 60vh;
    }
}

.ViewSystemOtherOptionsModalDescriptionHeader {
    h5 {
        color: #011a3c;
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 150%;
        margin: 0;
    }

    p {
        color: #4d4d4d;
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 150%;
        margin: 0;
    }

    @media only screen and (max-width: 500px) {
        h5 {
            line-height: 20px;
        }

        p {
            font-size: 14px;
            line-height: 20px;
        }
    }
}

.ViewSystemOtherOptionsCardWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.ViewSystemOtherOptionsCard {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid #e2eeff;
    background: #f9fcff;
    cursor: pointer;
}

.ViewSystemOtherOptionsCardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    span {
        display: flex;
        height: 23px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 3px;
        background: #e2eeff;
        color: #004aad;
        text-transform: uppercase;
        margin: 0;
        font-size: 12px;
        line-height: 20px;
        font-family: 'SF-Pro-Display-Semibold';
        letter-spacing: 1px;
    }
}

.ViewSystemOtherOptionsCardBody {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
}

.ViewSystemOtherOptionsCardRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    div {
        display: flex;
        flex-direction: column;
        gap: 4px;

        span {
            color: rgba(1, 26, 60, 0.85);
            font-family: 'SF-Pro-Display-Medium';
            font-size: 10px;
            letter-spacing: 0.55px;
            margin: 0;
        }
        p {
            color: #070c18;
            font-family: 'SF-Pro-Display-Bold';
            font-size: 14px;
            line-height: 120%;
            margin: 0;
        }
    }

    @media only screen and (max-width: 500px) {
        div {
            span {
                font-size: 14px;
            }
        }
    }
}

.ViewSystemOtherOptionsCardRowRight {
    span {
        text-align: right;
    }

    p {
        text-align: right;
    }
}

.ViewSystemOtherOptionsCardFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.ViewSystemOtherOptionsCardFooterInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    cursor: default;

    img {
        width: 16px;
        height: 16px;
    }

    p {
        color: #004aad;
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 100%;
        margin-bottom: 0 !important;
        padding: 0 I !important;
    }

    @media only screen and (max-width: 500px) {
        p {
            font-size: 14px;
        }
    }
}

.ViewSystem_TooltipIcon {
    width: 14px !important;
    height: 14px !important;
    flex-shrink: 0;
}

.ViewSystemTopTextCard_AftercareInfo {
    display: flex;
    align-items: center;
    gap: 4px;

    > span {
        font: 14px/24px var(--font-bold);
        color: #070c18;
        margin: 0;
    }
}

.ViewSystemTopTextCard_AftercareInfo_Stars {
    display: flex;
    align-items: center;
    gap: 4px;
}

.VS_TopRow_Right {
    display: flex;
    align-items: center;
    gap: 40px;

    &.UpdatedVS_TopRow_Right {
        gap: 24px;

        @media only screen and (max-width: 500px) {
            width: 100%;
            justify-content: space-between;
            gap: 0;
        }
    }
}

.AppliancesPreviewContainer_WhatItPowers {
    min-height: 334px !important;
    max-width: unset !important;
    margin: 24px 60px !important;

    @media only screen and (max-width: 500px) {
        min-height: unset !important;
        margin: 20px 16px !important;
    }
}

.ViewSystemAfterCareRating {
    border-radius: 16px;
    border: 0.5px solid #e5e5e5;
    background: linear-gradient(
        89deg,
        #fcfcfc -0.07%,
        #fff 47.92%,
        #f7f7f7 99.8%
    );
    display: flex;
    // height: 98px;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    grid-column: 1 / 3;
    margin: 66px 0px 66px 0;

    svg {
        height: 70px;
    }

    .SummaryAndTrigger {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            margin-left: 11px;
            align-items: flex-start;
            gap: 8px;
        }
    }

    @media screen and (min-width: 1400px) {
        min-width: 725px;
    }

    @media screen and (max-width: 768px) {
        height: auto;
        padding: 24px 9px;
    }
}

.ViewSystemAfterCareRatingModal {
    & > div:not(.ViewSystemAfterCareRatingNotes) {
        height: fit-content !important;
        justify-content: center;
        display: flex;
        position: relative;
        width: fit-content;
        margin: 0 auto;
        svg {
            height: 110px !important;
            width: auto !important;
            margin: 0 auto;
        }

        p {
            position: absolute;
            top: 40px;
            color: #191303;
            font-family: var(--font-bold);
            font-size: 28px;
            line-height: 140%;
        }
    }

    padding: 24px;

    .ViewSystemAfterCareRatingNotes {
        & > div {
            text-align: center;
            max-width: 85%;
            margin: 16px auto;

            p:nth-of-type(1) {
                color: #011a3c;
                font-size: 14px;
                line-height: 150%; /* 21px */
            }

            p:nth-of-type(2) {
                color: #4d4d4d;
                font-family: var(--font-light);
                font-size: 12px;
                line-height: 150%;
            }
        }
    }
}

.ViewSystemRatingSummary {
    color: #4d4d4d;
    font-size: 14px;
    line-height: 150%; /* 21px */
    margin: 0 auto 0 auto;
    max-width: 70%;

    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        max-width: 370px;
    }

    @media screen and (max-width: 768px) {
        font-size: 12px;
        max-width: 100%;
        margin: 0;
    }
}

.ViewSystemAfterCareRatingTrigger {
    color: #004aad;
    font-family: var(--font-semibold);
    font-size: 12px;
    line-height: normal;

    &:hover {
        cursor: pointer;
    }
}

.ViewSystemAfterCareRatingScore {
    position: relative;
    margin-top: 10px;

    .AfterCareRatingBadge {
        max-width: 132px;
        width: 100%;
    }

    p {
        position: absolute;
        top: 40px;
        left: 60px;
        color: #191303;
        font-family: var(--font-bold);
        font-size: 22px;
        line-height: 140%;

        @media screen and (max-width: 345px) {
            top: 38% !important;
            left: 38% !important;
        }

        @media screen and (max-width: 600px) {
            top: 40%;
            left: 38%;
            font-size: 18px;
            line-height: 24px;
        }
    }

    @media screen and (min-width: 768px) {
        p {
            top: 44% !important;
            left: 40% !important;
        }

        .AfterCareRatingBadge {
            max-width: 200px;
        }
    }

    @media screen and (max-width: 768px) {
        p {
            top: 45% !important;
            left: 40% !important;
        }

        .AfterCareRatingBadge {
            max-width: 250px;
        }
    }
}

.AddMargin {
    margin: 66px 0 0 0;
}

.PreviewFooterClass {
    @media screen and (max-width: 375px) {
        gap: 8px;
    }
}

.SelectedSolutionContentGrid {
    display: block !important;
    margin: 0 16px !important;

    .SelectedSolutionContentGridColumn {
        .ASOSelectedSolutionContentText:first-of-type {
            margin-bottom: 16px;
        }

        .ASOSelectedSolutionContentText {
            & > span {
                color: #4d4d4d;
                font-size: 12px;
                line-height: 150%; /* 18px */
            }

            & > h5 {
                color: #070c18;
                font-size: 14px;
                line-height: 150%; /* 21px */
                font-family: var(--font-regular);

                &.MonthlyRepayment {
                    span {
                        font-family: var(--font-semibold);
                        line-height: 120%;
                    }
                }
            }
            &.Right {
                span {
                    text-align: inherit;
                }
                h5 {
                    text-align: right;
                    display: flex;
                    justify-content: flex-end;
                }
            }
            svg {
                margin-left: 8px;
                width: 13.56px;
                height: 13.56px;
            }
        }

        .ViewSystemTabsOverviewItem:nth-of-type(even) {
            justify-self: flex-end;
            justify-content: flex-end;
        }

        .SystemCapacity {
            color: #070c18;
            font-size: 14px;
            line-height: 150%;
            margin-bottom: 8px;
        }
    }

    .ComponentsListing {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 20px;
        row-gap: 8px;
    }

    hr {
        margin-bottom: 16px;
    }

    .PackageDetails {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h5 {
            margin-bottom: 0 !important;
            color: #070c18;
            font-size: 14px;
            line-height: 150%;
        }

        .MonthlyRepayment {
            line-height: 150%;

            .tooltip {
                margin-top: -2px;
            }
        }

        & > div:first-of-type {
            div {
                margin-bottom: 16px;
            }
        }
    }

    .PoweringEssentialsItem {
        display: flex;
        align-items: center;

        img {
            width: 24px;
            height: 24px;
        }

        .PowerRating {
            color: #000;
            font-family: var(--font-light);
            font-size: 12px;
            font-style: normal;
            line-height: normal;
        }
    }
}
