.ATDTHead {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 35% 15%;
    padding: 20px;

    p {
        color: #011a3ccc;
        font-size: 13px;
    }

    @media screen and (min-width: 601px) and (max-width: 800px) {
        width: 88%;
    }
}

.seeUseHead {
    @media screen and (min-width: 601px) and (max-width: 800px) {
        width: 100% !important;
    }
}

.ATDTGridItem {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 35% 15%;
    padding: 15px 28px 15px 20px;
    background-color: #ffffff;
    align-items: center;
    border-radius: 6px;
    margin-bottom: 16px;

    &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
    }

    @media screen and (max-width: 500px) {
        height: 178px;
        padding: 36px 21px;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        margin-bottom: 15px;
        display: block;
    }

    @media screen and (min-width: 601px) and (max-width: 800px) {
        width: 88%;
    }
}

.sseGridItem {
    @media screen and (min-width: 601px) and (max-width: 800px) {
        width: 100% !important;
    }
}

.ATDTGridItemStatus {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: 500px) {
        justify-content: space-between;
    }
}

.ATDTGridItemStatusText {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    color: #011a3c;

    @media screen and (max-width: 500px) {
        width: 73px;
    }
}

.ATDTStatusTag {
    height: 23px;
    padding: 5.5px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 10px;
    }
}

.ATDTGridItemStatusDate {
    font-family: 'SF-Pro-Display';
}
.ATDTLargeTable {
    @media screen and (max-width: 500px) {
        display: none;
    }
}
.ATDTableMobile {
    @media screen and (min-width: 500px) {
        display: none;
    }
}

.MobileTimeUpdatedText {
    font-family: 'SF-Pro-Display';
    font-size: 13px;
    color: rgba(1, 26, 60, 0.8);
}

.PostApprovalChangesNotification {
    border-radius: 8px !important;
    border: 0.5px solid #ffda7a;
    background: #f9fcff !important;
    padding: 16px;

    .Content {
        margin-top: -23px;
        padding-bottom: 8px;
    }

    .title {
        display: flex;
        align-items: center;
        gap: 6px;
    }

    h6 {
        color: #3d5075;
        font-family: var(--font-medium);
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.78px;
        text-transform: uppercase;
        margin-bottom: 0;
    }

    hr {
        margin: 7px 0;
        border-color: rgba(61, 80, 117, 0.6);
    }

    .Notification {
        color: #000;
        font-size: 12px;
        line-height: 150%;
        font-family: var(--font-light);

        strong {
            font-weight: normal;
            font-family: var(--font-medium);
        }
    }

    .Container {
        display: flex;
        align-items: center;
        gap: 32px;
    }

    button {
        color: #004aad;
        font-family: var(--font-medium);
        font-size: 12px;
        line-height: normal;
        display: flex;
        max-width: 150px;
        height: fit-content;
        width: 100%;
        padding: 8px 12px 7px 12px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        align-self: stretch;
        border-radius: 30px;
        border: 0.5px solid var(--primary-004-aad, #004aad);
        background-color: transparent;

        &:hover {
            background: #004aad;
            border-radius: 30px;
            color: #ffffff;
            cursor: pointer;

            .ChangePackageSvg {
                stroke: #fff;
            }
        }
    }

    .ChangePackageSvg {
        stroke: #004aad;
    }

    .isClosed {
        padding: 9px 12px;
    }

    &.TenureChange {
        button {
            height: 36px;
        }

        @media only screen and (max-width: 500px) {
            .Container {
                gap: 8px;
            }

            button {
                width: 70px;
                height: 37px;
            }
        }
    }
}
