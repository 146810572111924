.Page {
    margin: 0 34px;
}

.subtitle,
.mainTitle {
    display: flex;
    justify-content: space-between;
    width: 90%;
    align-items: center;
}

.mainTitle {
    margin-top: 23px;
    margin-bottom: 50px;

    h2 {
        color: #011a3c;
        font-family: var(--font-semibold);
        font-size: 23px;
        line-height: normal;
    }
}

.subtitle {
    p {
        color: #011a3c;
        font-size: 15px;
        line-height: normal;
    }
    span {
        font-family: var(--font-semibold);
    }

    button {
        background: none;
        border: none;
        box-shadow: none;
        display: flex;
        gap: 8px;
        color: #004aad;
        font-family: var(--font-semibold);
        font-size: 14px;
        line-height: normal;

        &:hover {
            cursor: pointer;
        }

        svg {
            stroke: #004aad;
            width: 16px;
            height: 16px;
        }
    }
}

.packageCard {
    margin-top: 22px;
}

.Card {
    max-width: 490px;
}
