.ModalBox {
    .ant-modal-content {
        border-radius: 20px !important;
        max-width: 700px;
        padding: 25px;
        min-width: 200px;

        @media screen and (max-width: 640px) {
            padding: 0px;
            border-radius: 0px !important;
            height: 100%;
        }
    }

    .ant-carousel .slick-dots {
        justify-content: center;
        align-items: center;
        margin-left: 0;
        margin-right: 0;
    }

    @media screen and (max-width: 500px) {
        .ant-modal {
            max-width: 100vw !important;
            margin: unset;
            top: 0px;
            left: 0px;
            right: 0px;
            height: 100% !important;
            padding: 0;
        }

        .ant-modal-centered::before {
            content: unset;
        }

        .ant-modal-body {
            width: 100vw !important;
            min-height: 103vh;
            height: 100% !important;
            width: 459px;
            border-radius: 5px;
            display: flex;
            align-items: center;
        }
    }
}

.energyModalBox {
    .ant-modal-content {
        width: 550px;
    }

    .ant-modal {
        width: 45% !important;
    }

    .ant-modal {
        @media screen and (min-width: 1400px) {
            width: 40% !important;
        }
    }

    .ant-modal-body {
        padding: 24px 0px;
    }
}

.energyRecommendationsBox {
    .ant-modal-content {
        border-radius: 20px !important;
        max-width: 700px;
        padding: 0px;
        min-width: 200px;
    }

    .ant-carousel .slick-dots {
        justify-content: center;
        align-items: center;
        margin-left: 0;
        margin-right: 0;
    }

    @media screen and (max-width: 900px) {
        .ant-modal {
            width: 100% !important;
        }
    }

    @media screen and (max-width: 600px) {
        max-width: 100vw !important;
        width: 100vw !important;

        .ant-modal {
            border: 1px solid blue;
            max-width: 100vw !important;
            width: 100vw !important;
            height: 100vh I !important;
            min-height: 100vh !important;
            margin: unset;
            top: 0 !important;
            padding: 0;
        }

        .ant-modal-centered::before {
            content: unset;
        }

        .ant-modal-body {
            width: 100% !important;
            min-height: 100%;
            height: 100%;
            display: flex;
            align-items: center;
        }

        .ant-modal-content {
            border-radius: 0px !important;
            width: 100%;
            height: 100%;
        }
    }
}

.adminSolutionBox {
    .ant-modal-content {
        width: 624px;
        top: -50px;
        border-radius: 10px;
    }

    .ant-modal {
        width: 45% !important;
    }

    .ant-modal {
        @media screen and (min-width: 1400px) {
            width: 40% !important;
        }
    }

    .ant-modal-body {
        padding: 24px 0px;
    }

    @media only screen and (max-width: 500px) {
        .ant-modal-content {
            width: 100vw;
            top: 0;
        }
    }
}

.ModalBox2 {
    .ant-modal-content {
        border-radius: 10px !important;
        max-width: 700px;
        padding: 25px;
        min-width: 200px;

        .ant-modal-body {
            padding: 24px 0px;
        }

        @media screen and (max-width: 500px) {
            padding: 0px;
            border-radius: 0px !important;
        }
    }
}

.ModalBox3 {
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-modal {
        top: 0px !important;
        display: flex;
        justify-content: center;
    }

    .ant-modal-body {
        padding: 0px !important;
    }

    .ant-modal-content {
        border-radius: 10px;
    }
}

.NoPadding {
    .ant-modal-content {
        padding: 25px 0px;
    }
}

.NoPaddingBoth {
    .ant-modal-content {
        padding: 0px;
    }
}

.OnboardingModalBox {
    padding: 0;

    .ant-modal {
        padding-bottom: 0;
        position: relative;
        top: 50% !important;
        transform: translateY(-50%);
    }

    @media screen and (max-width: 600px) {
        .ant-modal {
            min-height: 100vh;
            max-width: 100vw;
            margin: 0;
            top: 0;
            transform: unset;
        }
    }

    .ant-modal-content {
        border-radius: 10px !important;

        @media screen and (max-width: 600px) {
            min-height: 100vh;
            border-radius: 0 !important;
            overflow: scroll;
        }
    }

    .ant-carousel .slick-dots {
        justify-content: center;
        align-items: center;
        margin-left: 0;
        margin-right: 0;
        position: relative;
        top: -35px;
        display: none !important;

        @media screen and (max-width: 600px) {
            top: 10px;
        }
    }
}

.ant-modal-close {
    top: 10px;

    @media screen and (max-width: 600px) {
        top: 10px;
    }
}

@media screen and (max-width: 500px) {
    .ant-modal-wrap {
        &.ModalBox3 {
            min-height: 100vh;
            align-items: flex-start;
        }
    }
}

.PromoModal {
    .ant-modal {
        @media screen and (min-width: 1400px) {
            width: 40% !important;
        }
    }

    .ant-modal-body {
        padding: 0;

        @media screen and (max-width: 500px) {
            min-height: 22vh;
            margin-top: 50%;
            height: 100% !important;
            width: 100% !important;
        }
    }

    .ant-modal-content {
        border-radius: 20px;
        max-height: 337px;

        @media screen and (max-width: 500px) {
            margin-left: 4%;
            max-height: 337px;
            max-width: 379px;
        }

        @media screen and (max-width: 390px) {
            max-height: 320px;
            max-width: 350px;
        }
    }

    .ant-modal-close {
        top: -50px;
        right: -20px;
    }

    .anticon {
        color: #fff;
    }
}

.TenPxModalRadius {
    .ant-modal-content {
        border-radius: 10px !important;

        @media screen and (max-width: 640px) {
            border-radius: 0px !important;
        }
    }
}
