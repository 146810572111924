.DropdownContainer {
    padding: 16px;
    margin: 24px 0;
    border-radius: 8px;
    border: 1px solid #e2eeff;
    background: #f9fcff;
    background-color: white;
    border-radius: 16px;
    border: 1px solid #e2eeff;
}

.DetailsDropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.isOpen {
        padding: 0px 0px 26px 0px;
    }

    &:hover {
        cursor: pointer;
    }

    p {
        font-size: 14px;
        line-height: 21px;
        font-family: var(--font-semibold);
    }
}

.isClosed {
    p {
        font-family: var(--font-regular) !important;
    }
}

.arrowIcon:hover {
    cursor: pointer;
}
