.label {
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.1em;
    color: #61708e;
    margin-top: 16px;

    @media screen and (max-width: 600px) {
        margin-top: 30px;
    }
}
.Wrapper {
    max-height: 80vh;
    overflow: auto;
    padding: 10px 60px;

    @media screen and (max-width: 600px) {
        padding: 30px 20px;
    }
}

.Title {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 20px;
    line-height: 24px;
    color: #011a3c;
    margin-right: 3px;
}
.SubTitle {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.055em;
    color: rgba(1, 26, 60, 0.85);
    margin: 19px 0 15px 0;
}
.RecommenadationParagraph {
    font-family: 'SF-Pro-Display';
    font-size: 15px;
    line-height: 25px;
    color: rgba(1, 26, 60, 0.75);
    margin: 25px 0;
}
.ComponentTitle {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    line-height: 17px;
    color: #011a3c;
    margin: 25px 0;
}
.ButtonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
.ComponentName {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 14px;
    line-height: 17px;
    color: #011a3c;
}
.ComponentType {
    font-family: 'SF-Pro-Display';
    font-size: 13px;
    line-height: 16px;
    color: rgba(1, 26, 60, 0.8);

    @media screen and (max-width: 600px) {
        display: none;
    }
}
.ComponentAmount {
    font-family: 'SF-Pro-Display';
    font-size: 13px;
    line-height: 16px;
    color: rgba(1, 26, 60, 0.8);
}
.ComponentQty {
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 13px;
    line-height: 16px;
    color: #011a3c;
}
.ComponentDetails {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid #e5e5e5;
    margin-top: 30px;
    padding-bottom: 18px;

    p {
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 14px;
        line-height: 17px;
        color: #011a3c;
    }
}

.ComponentDetailsTotal {
    @extend .ComponentDetails;
    margin-top: 0;
}

.SelectedComponents {
    border-bottom: 0.5px solid #e5e5e5;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 58px;
    width: 100%;
}

.InputTitle {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 13px;
    line-height: 16px;
    color: #011a3c;
    margin-bottom: 10px;
}
.EnergyCapacity {
    font-family: 'SF-Pro-Display-Thin';
    color: #011a3c;
    margin-left: 5px;
}
