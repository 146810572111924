.ViewRecommendationsWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
}

.RecommendedSolutionTab {
    .ant-tabs-tab {
        margin-left: 87px;

        @media screen and (max-width: 600px) {
            margin-left: 20px;
            width: 150px;
        }
    }

    .ant-tabs-content-holder {
        width: 100%;
    }
}

.BackNavWrapper {
    margin-left: 20px;
    margin-bottom: -18px;
    display: none;

    @media screen and (max-width: 600px) {
        display: block;
    }
}
